import classNames from 'classnames'
import { useCallback } from 'react'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { useRoute } from '../../../../hooks/useRoute'
import { OpenConfirmationModal } from '../../../../services/event-bus/events'
import { Button } from '../../../UI/Button'
import { Input } from '../../../UI/Input'
import { InstructionsField } from './Fields/InstructionsField'
import { NoteTypeField } from './Fields/NoteTypeField'
import { HeadingSubForm } from './HeadingSubForm'
import { DividerLine, FieldCol, FieldRow } from './styles'
import { useLocalContext } from './useLocalContext'

interface Props {
    className?: string
    isCreating: boolean
}

const Styles = styled.div``

export const Form = ({ className, isCreating }: Props) => {
    const customTemplate = useLocation().state?.customTemplate
    const { navTemplates } = useRoute()
    const { form, updateField, validateField, submit, deleteTemplate } =
        useLocalContext().form
    const { forms: headingSubForms } = useLocalContext().headingSubForm

    const onSubmit = useCallback(() => {
        submit(headingSubForms, () => {
            navTemplates()
        })
    }, [headingSubForms, navTemplates, submit])

    const onDelete = useCallback(() => {
        if (!customTemplate?._id) return

        OpenConfirmationModal.emit({
            titleNode: 'Delete template',
            bodyNode: 'Are you sure you want to delete this template?',
            confirmLabel: 'Delete',
            cancelIntent: 'danger',
            onConfirm: () => {
                deleteTemplate(customTemplate._id, () => {
                    navTemplates()
                })
            },
        })
    }, [customTemplate?._id, deleteTemplate, navTemplates])

    return (
        <Styles className={classNames(className)}>
            <FieldRow
                label="Template name"
                inputNode={
                    <Input
                        name="templateName"
                        placeholder="Enter template name..."
                        initialValue={(form.templateName.value as string) ?? ''}
                        error={form.templateName.error}
                        onChange={(value) => updateField('templateName', value)}
                        onBlur={() => validateField('templateName')}
                    />
                }
            />
            <DividerLine />
            <NoteTypeField
                value={(form.type.value as string) ?? ''}
                onChange={(value) => updateField('type', value)}
            />
            <DividerLine />
            <InstructionsField
                values={(() => {
                    const values = []
                    if (form.abbreviations.value) values.push('abbreviations')
                    if (form.shortPhrases.value) values.push('shortPhrases')
                    if (form.fullSentenses.value) values.push('fullSentenses')
                    return values
                })()}
                onChange={(key, value) => updateField(key as any, value)}
            />

            <DividerLine />
            <HeadingSubForm />
            <DividerLine />
            <FieldCol
                label="Additional details"
                inputNode={
                    <Input
                        name="addtionalDetails"
                        placeholder="Enter additional details..."
                        multiline
                        initialValue={
                            (form.additionalDetails.value as string) ?? ''
                        }
                        onChange={(value) =>
                            updateField('additionalDetails', value)
                        }
                    />
                }
            />
            <DividerLine />
            <div className="flex justify-end">
                <Button
                    className="mr-3"
                    intent="text-secondary"
                    label="Cancel"
                    onClick={navTemplates}
                />
                {customTemplate && (
                    <Button
                        className="mr-3"
                        intent="text-danger"
                        label="Delete"
                        onClick={onDelete}
                    />
                )}
                <Button
                    label={isCreating ? 'Create template' : 'Update template'}
                    onClick={onSubmit}
                />
            </div>
        </Styles>
    )
}
