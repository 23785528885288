import { useMemo } from 'react'

import { Language, LANGUAGES, LANGUAGES_LABEL } from '../../constants'
import { ListBox, ListBoxOption } from './ListBox'

interface Props {
    value: Language | undefined
    onChange(value: Language): void
}

export const LanguagesListBox = ({ value, onChange }: Props) => {
    const optionSelected = useMemo(
        () => (value ? options.find((i) => i.value === value) : undefined),
        [value]
    )

    return (
        <ListBox
            placeholder="Select language"
            optionSelected={optionSelected}
            options={options}
            onChange={(option) => onChange(option.value as Language)}
        />
    )
}

const options: ListBoxOption[] = LANGUAGES.map((language) => {
    return {
        value: language,
        displayNode: LANGUAGES_LABEL[language] ?? language,
    }
})
