import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import styled from 'styled-components'

interface Props {
    className?: string
    options: RadioOption[]
    value: string
    onChange(value: string): void
}

export interface RadioOption {
    value: string
    label?: string
}

const Styles = styled.div``

export const Radio = ({ className, value, options, onChange }: Props) => {
    return (
        <Styles className={classNames(className)}>
            <RadioGroup value={value} onChange={onChange}>
                {options.map((option) => (
                    <RadioGroup.Option key={option.value} value={option.value}>
                        {({ checked }) => (
                            <div className="flex items-center">
                                {checked ? <CheckedIcon /> : <UncheckedIcon />}
                                {option.label && (
                                    <label
                                        htmlFor={`radio-${option.value}`}
                                        className="ml-3"
                                    >
                                        {option.label}
                                    </label>
                                )}
                            </div>
                        )}
                    </RadioGroup.Option>
                ))}
            </RadioGroup>
        </Styles>
    )
}

const CheckedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#406CCF"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        </svg>
    )
}

const UncheckedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#e8eaed"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        </svg>
    )
}
