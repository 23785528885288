import { AxiosResponse } from 'axios'
import { forkJoin, map, Observable } from 'rxjs'

import { VisitNoteGptResponses } from '../models/VisitNote.model'
import { postMessageService } from '../postMessage.service'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const identifyFillableEForms = async (
    visitId: string,
    visitNoteId: string,
    eforms: any
): Promise<any> => {
    const url = `${baseUrl}/gpt/eforms/identify-fillables`
    const payload = { visitId, visitNoteId, eforms }
    return authAxios.post(url, payload).then((response: AxiosResponse) => {
        return response.data
    })
}

const fillEForm = async (
    visitId: string,
    visitNoteId: string,
    eformJson: any
): Promise<any> => {
    const url = `${baseUrl}/gpt/eforms/fill`
    const payload = { visitId, visitNoteId, eformJson }
    return authAxios.post(url, payload).then((response: AxiosResponse) => {
        return response.data
    })
}

const fillCpp = async (
    visitId: string,
    visitNoteId: string,
    cppJson: any
): Promise<any> => {
    const url = `${baseUrl}/gpt/cpp/fill`
    const payload = { visitId, visitNoteId, cppJson }
    return authAxios.post(url, payload).then((response: AxiosResponse) => {
        return response.data
    })
}

const makeRequests = (
    visitId: string,
    visitNoteId: string
): Observable<{
    key: keyof VisitNoteGptResponses
    gptResponse: any
}> => {
    return new Observable((observer) => {
        try {
            const patientContext = postMessageService.getPatientContext()
            const eforms = postMessageService.getPatientContextEForms()
            const cpp = patientContext?.cpp || {}

            gptService
                .fillCpp(visitId, visitNoteId, cpp)
                .then((gptResponse) => {
                    observer.next({
                        key: 'autoFilledCpp',
                        gptResponse,
                    })
                })

            const eformsToIdentify = eforms.map((eform) => ({
                id: eform.fid,
                name: eform.formName,
                description: eform.jsonData?.description || eform.subject || '',
            }))

            gptService
                .identifyFillableEForms(visitId, visitNoteId, eformsToIdentify)
                .then((gptResponse) => {
                    const identifiedFillableEForms = (
                        gptResponse?.eforms || []
                    ).filter((i: any) => eforms.some((j) => j.fid === i.id))

                    observer.next({
                        key: 'identifiedFillableEForms',
                        gptResponse: identifiedFillableEForms,
                    })

                    let autofillEForms = eforms.filter((i) =>
                        identifiedFillableEForms.some(
                            (j: any) => j.id === i.fid
                        )
                    )

                    // Limit autofillEForms to 3
                    autofillEForms = autofillEForms?.slice(0, 3) ?? []

                    // Send separate requests to fill each eform
                    const fillEFormRequests = autofillEForms.map((eform) =>
                        gptService.fillEForm(visitId, visitNoteId, {
                            ...eform.jsonData,
                            id: eform.fid,
                            name: eform.formName,
                        })
                    )
                    return forkJoin(fillEFormRequests)
                        .pipe(
                            map((autoFilledResponses: any[]) => {
                                const autoFilledEForms = autoFilledResponses
                                    .map((response) => response?.eforms || [])
                                    .flat()

                                observer.next({
                                    key: 'autoFilledEForms',
                                    gptResponse: autoFilledEForms,
                                })
                                observer.complete()
                            })
                        )
                        .toPromise()
                })
                .catch((error) => {
                    console.error('Error during GPT request:', error)
                    observer.error('Error during GPT request: ' + error)
                })
        } catch (error) {
            console.error('Unexpected error during GPT request:', error)
            observer.error('Unexpected error during GPT request: ' + error)
        }
    })
}

export const gptService = {
    identifyFillableEForms,
    fillEForm,
    fillCpp,
    makeRequests,
}
