import classNames from 'classnames'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import Colors from '../../colors'
import { OpenAutofillEFormsModal } from '../../services/event-bus/events'
import { User } from '../../services/models/User.model'
import { VisitNoteGptResponses } from '../../services/models/VisitNote.model'
import { useAuthContext } from '../Authentication/hooks/useAuthContext'

interface Props {
    className?: string
    visitId: string
    visitNoteId: string
    gptResponses: VisitNoteGptResponses | undefined
    childNode(isLoading: boolean, count: number): ReactNode
}

const Styles = styled.div`
    position: relative;
`

export const AutofillEFormsTrigger = ({
    className,
    visitId,
    visitNoteId,
    gptResponses,
    childNode,
}: Props) => {
    const { user } = useAuthContext().user
    const [fillableEForms, setFillableEForms] = useState<any[]>([])

    useEffect(() => {
        const _fillableEForms = Array.from(
            gptResponses?.identifiedFillableEForms ?? []
        ).filter((i: any) => !!i.id)
        setFillableEForms(_fillableEForms)
    }, [gptResponses?.identifiedFillableEForms])

    const isEmrUser = useMemo(
        () => (user ? User.isEmrUser(user) : false),
        [user]
    )

    const onClick = useCallback(
        async (event: any) => {
            event.stopPropagation()
            OpenAutofillEFormsModal.emit({
                visitId,
                visitNoteId,
                fillableEForms,
                gptResponses,
            })
        },
        [visitId, visitNoteId, fillableEForms, gptResponses]
    )

    const count = useMemo(() => fillableEForms?.length ?? 0, [fillableEForms])

    if (!isEmrUser) {
        return null
    }

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(false, count)}
            <CountNode count={fillableEForms?.length} />
        </Styles>
    )
}

const CountNodeStyles = styled.div`
    position: absolute;
    top: -8px;
    right: -12px;
    background-color: ${Colors.RED}CC;
    color: white;
    border-radius: 100px;
    font-size: 12px;
    font-weight: bold;
    padding: 2px 8px;
`

const CountNode = ({ count }: { count: number }) => {
    if (!count) {
        return null
    }

    return <CountNodeStyles>{count}</CountNodeStyles>
}
