import classNames from 'classnames'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Checkbox } from '../../../../UI/Checkbox'

interface Props {
    className?: string
    values: string[]
    onChange(key: string, value: boolean): void
}

interface Option {
    value: string
    label: string
}

const Styles = styled.div``

export const HeadingFormatsField = ({ className, values, onChange }: Props) => {
    const options = useMemo((): Option[] => {
        return [
            {
                value: 'bullets',
                label: 'Bullet point format',
            },
            {
                value: 'paragraph',
                label: 'Paragraph format',
            },
            {
                value: 'numbered',
                label: 'Number section',
            },
            {
                value: 'do_not_include_if_not_mentioned',
                label: 'Do not include if not mentioned',
            },
            {
                value: 'combine_sections_all_together',
                label: 'Combine sections all together',
            },
        ]
    }, [])

    return (
        <Styles className={classNames(className)}>
            {options.map((option) => (
                <Checkbox
                    key={option.value}
                    value={values.includes(option.value) ? 'true' : 'false'}
                    label={option.label}
                    onChange={() =>
                        onChange(option.value, !values.includes(option.value))
                    }
                />
            ))}
        </Styles>
    )
}
