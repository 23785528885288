import { useCallback, useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'

import { useAppContext } from '../../../../hooks/useAppContext'
import { useHttp } from '../../../../hooks/useHttp'
import {
    BooleanField,
    Field,
    FieldValue,
    initialBooleanField,
    initialTextField,
    TextField,
} from '../../../../lib/field'
import { customTemplatesService } from '../../../../services/http/customTemplates.service'
import {
    CustomTemplate,
    CustomTemplateContent,
} from '../../../../services/models/CustomTemplate.model'
import { useAuthContext } from '../../../Authentication/hooks/useAuthContext'
import { FormFields as HeadingSubForm } from './useHeadingSubForm'

class FormFields {
    templateName!: TextField
    type!: TextField
    abbreviations!: BooleanField
    shortPhrases!: BooleanField
    fullSentenses!: BooleanField
    additionalDetails!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    templateName: initialTextField,
    type: {
        value: 'visit',
        error: null,
    },
    abbreviations: initialBooleanField,
    shortPhrases: initialBooleanField,
    fullSentenses: initialBooleanField,
    additionalDetails: initialTextField,
}

interface Action {
    fieldKey: FormFieldsKeys
    payload: Field
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: Action
): FormFields => {
    return {
        ...form,
        [fieldKey]: payload as TextField,
    }
}

export interface FormHookState {
    form: FormFields
    isLoading: boolean
    updateField(fieldKey: FormFieldsKeys, fieldValue: FieldValue): void
    validateField(fieldKey: FormFieldsKeys): boolean
    submit(headingSubForms: HeadingSubForm[], callback: () => void): void
    deleteTemplate(id: string, callback: () => void): void
}

export const useForm = (
    customTemplate: CustomTemplate | undefined
): FormHookState => {
    const { user } = useAuthContext().user
    const { addCustomTemplate, updateCustomTemplate, deleteCustomTemplate } =
        useAppContext().templates
    const { isLoading, createReq, updateReq, deleteReq } = useHttpReq()
    const [form, dispatch] = useReducer(
        reducer,
        Object.assign({}, initialFormFields)
    )

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            dispatch({
                fieldKey,
                payload: {
                    value: form[fieldKey].value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const value = form[fieldKey].value

            switch (fieldKey) {
                case 'templateName':
                case 'type': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    }
                    return true
                }

                default: {
                    return false
                }
            }
        },
        [form, updateFieldError]
    )

    const validateForm = useCallback((): boolean => {
        const validations: boolean[] = []

        validations.push(validateField('templateName'))
        validations.push(validateField('type'))

        return !validations.some((i: boolean) => i === false)
    }, [validateField])

    const submit = useCallback(
        (headingSubForms: HeadingSubForm[], callback: () => void) => {
            const userId = user?._id
            if (!userId) {
                return
            }

            if (!validateForm()) {
                return
            }

            // Update custom template
            if (customTemplate?._id) {
                const updates: Partial<CustomTemplate> = {
                    ...customTemplate,
                    name: (form.templateName.value as string) ?? '',
                    content: {
                        ...customTemplate.content,
                        type: form.type.value as any,
                        headings: headingSubForms
                            .filter(
                                (headingSubForm) =>
                                    !!headingSubForm.headingName.value
                            )
                            .map((headingSubForm) => {
                                return {
                                    heading: headingSubForm.headingName
                                        .value as any,
                                    formats: (() => {
                                        const formats = []
                                        if (headingSubForm.bullets.value)
                                            formats.push('bullets')
                                        if (headingSubForm.paragraph.value)
                                            formats.push('paragraph')
                                        if (headingSubForm.numbered.value)
                                            formats.push('numbered')
                                        if (
                                            headingSubForm
                                                .do_not_include_if_not_mentioned
                                                .value
                                        )
                                            formats.push(
                                                'do_not_include_if_not_mentioned'
                                            )
                                        if (
                                            headingSubForm
                                                .combine_sections_all_together
                                                .value
                                        )
                                            formats.push(
                                                'combine_sections_all_together'
                                            )
                                        return formats
                                    })(),
                                    instructions: [],
                                    customHeading: headingSubForm
                                        .canUpdateHeader.value
                                        ? headingSubForm.headingName.value ??
                                          undefined
                                        : undefined,
                                    customInstruction:
                                        headingSubForm.additionalDetails
                                            .value ?? undefined,
                                } as any
                            }),
                        instructions: (() => {
                            const instructions = []
                            if (form.abbreviations.value)
                                instructions.push('medical_abbreviations')
                            if (form.shortPhrases.value)
                                instructions.push('short_phrases')
                            if (form.fullSentenses.value)
                                instructions.push('full_sentences')
                            return instructions as any
                        })(),
                        additionalDetails: form.additionalDetails
                            .value as string,
                    },
                }
                updateReq(customTemplate._id, updates).then(() => {
                    toast.success('Successfully updated custom template')
                    updateCustomTemplate(updates)
                    callback()
                })
            }

            // Create new custom template
            else {
                createReq(userId, form.templateName.value as string, {
                    type: form.type.value as any,
                    headings: headingSubForms
                        .filter(
                            (headingSubForm) =>
                                !!headingSubForm.headingName.value
                        )
                        .map((headingSubForm) => {
                            return {
                                heading: headingSubForm.headingName
                                    .value as any,
                                formats: (() => {
                                    const formats = []
                                    if (headingSubForm.bullets.value)
                                        formats.push('bullets')
                                    if (headingSubForm.paragraph.value)
                                        formats.push('paragraph')
                                    if (headingSubForm.numbered.value)
                                        formats.push('numbered')
                                    if (
                                        headingSubForm
                                            .do_not_include_if_not_mentioned
                                            .value
                                    )
                                        formats.push(
                                            'do_not_include_if_not_mentioned'
                                        )
                                    if (
                                        headingSubForm
                                            .combine_sections_all_together.value
                                    )
                                        formats.push(
                                            'combine_sections_all_together'
                                        )
                                    return formats
                                })(),
                                instructions: [],
                                customHeading: headingSubForm.canUpdateHeader
                                    .value
                                    ? headingSubForm.headingName.value ??
                                      undefined
                                    : undefined,
                                customInstruction:
                                    headingSubForm.additionalDetails.value ??
                                    undefined,
                            } as any
                        }),
                    instructions: (() => {
                        const instructions = []
                        if (form.abbreviations.value)
                            instructions.push('medical_abbreviations')
                        if (form.shortPhrases.value)
                            instructions.push('short_phrases')
                        if (form.fullSentenses.value)
                            instructions.push('full_sentences')
                        return instructions as any
                    })(),
                    additionalDetails: form.additionalDetails.value as string,
                }).then((customTemplate) => {
                    toast.success('Successfully created custom template')
                    addCustomTemplate(customTemplate)
                    callback()
                })
            }
        },
        [
            user?._id,
            validateForm,
            customTemplate,
            form.templateName.value,
            form.type.value,
            form.abbreviations.value,
            form.shortPhrases.value,
            form.fullSentenses.value,
            form.additionalDetails.value,
            addCustomTemplate,
            updateCustomTemplate,
            createReq,
            updateReq,
        ]
    )

    const deleteTemplate = useCallback(
        (id: string, callback: () => void) => {
            deleteReq(id).then(() => {
                toast.success('Successfully deleted custom template')
                deleteCustomTemplate(id)
                callback()
            })
        },
        [deleteCustomTemplate, deleteReq]
    )

    useEffect(() => {
        if (!customTemplate) {
            return
        }
        updateField('templateName', customTemplate.name)
        updateField('type', customTemplate.content.type)
        updateField(
            'additionalDetails',
            customTemplate.content.additionalDetails ?? ''
        )
        customTemplate.content.instructions?.forEach((instruction) => {
            switch (instruction) {
                case 'medical_abbreviations':
                    updateField('abbreviations', true)
                    break
                case 'short_phrases':
                    updateField('shortPhrases', true)
                    break
                case 'full_sentences':
                    updateField('fullSentenses', true)
                    break
            }
        })
    }, [customTemplate, updateField])

    return {
        form,
        isLoading,
        updateField,
        validateField,
        submit,
        deleteTemplate,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const createReq = useCallback(
        (
            userId: string,
            name: string,
            content: CustomTemplateContent
        ): Promise<CustomTemplate> =>
            sendRequest(
                customTemplatesService.createCustomTemplate.bind(
                    {},
                    userId,
                    name,
                    content
                )
            ),
        [sendRequest]
    )

    const updateReq = useCallback(
        async (id: string, updates: Partial<CustomTemplate>): Promise<void> =>
            sendRequest(
                customTemplatesService.updateCustomTemplate.bind(
                    {},
                    id,
                    updates
                )
            ),
        [sendRequest]
    )

    const deleteReq = useCallback(
        async (id: string): Promise<void> =>
            sendRequest(
                customTemplatesService.deleteCustomTemplate.bind({}, id)
            ),
        [sendRequest]
    )

    return {
        isLoading,
        createReq,
        updateReq,
        deleteReq,
    }
}
