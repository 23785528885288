import { CheckIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { useAppContext } from '../../../../../hooks/useAppContext'
import useVisitNotesContext from '../../../../../hooks/useVisitNotesContext'
import { CustomTemplate } from '../../../../../services/models/CustomTemplate.model'
import { Template } from '../../../../../services/models/Template.model'
import { Button } from '../../../../UI/Button'
import { useVisitsContext } from '../../../../Visits/hooks/useVisitsContext'

const Styles = styled.div`
    button {
        min-height: unset;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 8px;

        & > div {
            padding-left: 4px;
        }
    }
`

export const ChangeTemplate = () => {
    const { id: visitId } = useParams()
    const { getVisit } = useVisitsContext().visits
    const { allTemplates, getTemplate } = useAppContext().templates
    const { generateVisitNote, isProcessing } =
        useVisitNotesContext().visitNotes

    const visit = useMemo(
        () => (visitId ? getVisit(visitId) : undefined),
        [visitId, getVisit]
    )

    const currentTemplate = useMemo(():
        | Template
        | CustomTemplate
        | undefined => {
        if (visit?.customTemplateId) {
            return getTemplate(visit.customTemplateId)
        } else if (visit?.templateId) {
            return getTemplate(visit.templateId)
        }
        return undefined
    }, [visit?.templateId, visit?.customTemplateId, getTemplate])

    return (
        <Styles className="flex flex-wrap px-3 pt-3 pb-1 rounded-md border bg-white">
            {allTemplates.map((template) => {
                const isSelected = currentTemplate?._id === template._id
                const isCustomTemplate = (template as CustomTemplate).isCustom

                return (
                    <Button
                        key={template._id}
                        className={classNames(
                            'mr-2 mb-2 ring-1 ring-gray-300 font-medium',
                            {
                                'pl-7': isSelected,
                            }
                        )}
                        intent={isSelected ? 'primary-fade' : 'text-secondary'}
                        label={template.name}
                        leftIconNode={
                            isSelected && <CheckIcon className="h-5 w-5" />
                        }
                        onClick={() =>
                            generateVisitNote(
                                'change-template',
                                !isCustomTemplate ? template._id : undefined,
                                isCustomTemplate ? template._id : undefined
                            ).catch((error) => {
                                console.error(
                                    'Error generating visit note:',
                                    error
                                )
                            })
                        }
                        disabled={isProcessing}
                    />
                )
            })}
        </Styles>
    )
}
