import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import { useCallback } from 'react'
import { useLocation } from 'react-router'

import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useRoute } from '../../../hooks/useRoute'
import { Button } from '../../UI/Button'
import { IconButton } from '../../UI/IconButton'

export const Navigation = () => {
    return (
        <div className="block sm:flex sm:items-center mb-6">
            <BackButton />
            <PageTitle />
        </div>
    )
}

const pageTitle: Record<string, string> = {
    '/settings/account': 'Settings',
    '/settings/templates': 'Templates',
    '/settings/templates/new': 'New template',
    '/settings/templates/id': 'Update template',
    '/settings/billing': 'Billing',
    '/settings/billing/update': 'Choose your Fluent plan',
    '/settings/billing/checkout': 'Checkout',
    '/settings/developer': 'Developer',
    '/settings/request-feature': 'Request a feature',
}

const PageTitle = () => {
    let { pathname } = useLocation()
    pathname = pathname.replace(/\/checkout\/.*/, '/checkout')
    pathname = pathname.replace(/\/templates\/(?!new).*$/, '/templates/id')

    return (
        <div className="text-xl font-medium flex items-center">
            {pageTitle[pathname]}
        </div>
    )
}

const BackButton = () => {
    const { navVisits } = useRoute()
    const { pathname } = useLocation()
    const { isMobile } = useMediaQuery()

    const onBack = useCallback(() => {
        if (
            pathname.startsWith('/settings/billing/update') ||
            pathname.startsWith('/settings/templates/')
        ) {
            window.history.back()
        } else {
            navVisits()
        }
    }, [pathname, navVisits])

    return isMobile ? (
        <Button
            className="mb-6"
            intent="text"
            label="Back"
            leftIconNode={<ChevronLeftIcon className="h-5 w-5 text-gray-400" />}
            onClick={onBack}
        />
    ) : (
        <IconButton
            className="mr-6"
            iconNode={<ChevronLeftIcon className="h-5 w-5 text-gray-400" />}
            onClick={onBack}
        />
    )
}
