import { useEffect, useMemo } from 'react'
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    useLocation,
    useParams,
} from 'react-router-dom'

import {
    AuthContextProvider,
    useAuthContext,
} from './components/Authentication/hooks/useAuthContext'
import { BillingContextProvider } from './components/Billing/hooks/useBillingContext'
import { DeveloperContextProvider } from './components/Settings/Developer/hooks/useDeveloperContext'
import { Loader } from './components/UI/Loader'
import {
    useVisitsContext,
    VisitsContextProvider,
} from './components/Visits/hooks/useVisitsContext'
import { AppContextProvider } from './hooks/useAppContext'
import { NotepadContextProvider } from './hooks/useNotepadContext'
import { useRoute } from './hooks/useRoute'
import { VisitNotesContextProvider } from './hooks/useVisitNotesContext'
import { emr } from './lib/emr'
import { googlead } from './lib/googlead'
import { AccountPage } from './pages/AccountPage'
import { AuthCheckPage } from './pages/AuthCheckPage'
import { BillingCheckoutPage } from './pages/BillingCheckoutPage'
import { BillingPage } from './pages/BillingPage'
import { BillingUpdatePage } from './pages/BillingUpdatePage'
import { DeveloperPage } from './pages/DeveloperPage'
import { ForgotPasswordPage } from './pages/ForgotPasswordPage'
import { MainPage } from './pages/MainPage'
import { ManageTemplatesPage } from './pages/ManageTemplatesPage'
import { RequestFeaturePage } from './pages/RequestFeaturePage'
import { ResetPasswordPage } from './pages/ResetPasswordPage'
import { SettingsPage } from './pages/SettingsPage'
import { SignInPage } from './pages/SignInPage'
import { SignUpCompletionPage } from './pages/SignUpCompletionPage'
import { SignUpPage } from './pages/SignUpPage'
import { TemplateFormPage } from './pages/TemplateFormPage'
import { VisitPage } from './pages/VisitPage'
import { VisitRecordPage } from './pages/VisitRecordPage'
import { VisitsPage } from './pages/VisitsPage'
import { VisitWelcomePage } from './pages/VisitWelcomePage'
import { Portal } from './Portal'
import { Visit as VisitModel } from './services/models/Visit.model'
import { postMessageService } from './services/postMessage.service'

export const App = () => {
    useEffect(() => {
        // Initialize google ads
        googlead.init()

        // Resolve session fomr EMR integration
        emr.resolveSession()

        // Send loaded message to parent window
        postMessageService.sendLoaded()
    }, [])

    return (
        <Router>
            <AuthContextProvider>
                <AppRoutes />
            </AuthContextProvider>
        </Router>
    )
}

const AppRoutes = () => {
    const {
        signInRoute,
        signUpRoute,
        signUpCompletionRoute,
        forgotPasswordRoute,
        resetPasswordRoute,
        authCheckRoute,
        mainRoute,
        visitsRoute,
        visitRoute,
        visitStartRoute,
        visitRecordRoute,
        settingsRoute,
        accountRoute,
        templatesRoute,
        templateFormRoute,
        billingRoute,
        billingUpdateRoute,
        billingCheckoutRoute,
        developerRoute,
        requestFeatureRoute,
    } = useRoute()
    return (
        <Routes>
            <Route path={signInRoute} element={<SignInPage />} />
            <Route path={signUpRoute} element={<SignUpPage />} />
            <Route
                path={signUpCompletionRoute}
                element={<SignUpCompletionPage />}
            />
            <Route
                path={forgotPasswordRoute}
                element={<ForgotPasswordPage />}
            />
            <Route path={resetPasswordRoute} element={<ResetPasswordPage />} />
            <Route path={authCheckRoute} element={<AuthCheckPage />} />
            <Route
                path={mainRoute}
                element={
                    <RequiresAuth authCheckRoute={authCheckRoute}>
                        <AppContextProvider>
                            <VisitNotesContextProvider>
                                <DeveloperContextProvider>
                                    <BillingContextProvider>
                                        <MainPage />
                                        <Portal />
                                    </BillingContextProvider>
                                </DeveloperContextProvider>
                            </VisitNotesContextProvider>
                        </AppContextProvider>
                    </RequiresAuth>
                }
            >
                <Route
                    path={visitsRoute}
                    element={
                        <NotepadContextProvider>
                            <VisitsContextProvider>
                                <VisitsPage />
                            </VisitsContextProvider>
                        </NotepadContextProvider>
                    }
                >
                    <Route index element={<VisitWelcomePage />} />
                    <Route
                        path={visitStartRoute}
                        element={<VisitWelcomePage />}
                    />
                    <Route
                        path={visitRecordRoute}
                        element={
                            <CanRouteToRecordVisitPage
                                redirectPath={visitRoute}
                            >
                                <VisitRecordPage />
                            </CanRouteToRecordVisitPage>
                        }
                    />
                    <Route path={visitRoute} element={<VisitPage />} />
                    <Route
                        path={`${visitsRoute}/*`}
                        element={<Navigate to={visitStartRoute} replace />}
                    />
                </Route>
                <Route path={settingsRoute} element={<SettingsPage />}>
                    <Route path={accountRoute} element={<AccountPage />} />
                    <Route
                        path={templatesRoute}
                        element={<ManageTemplatesPage />}
                    />
                    <Route
                        path={templateFormRoute}
                        element={<TemplateFormPage />}
                    />
                    <Route path={billingRoute} element={<BillingPage />} />
                    <Route
                        path={billingUpdateRoute}
                        element={<BillingUpdatePage />}
                    />
                    <Route
                        path={billingCheckoutRoute}
                        element={<BillingCheckoutPage />}
                    />
                    <Route
                        path={developerRoute}
                        element={
                            <CanRouteToDeveloperPage
                                redirectPath={visitStartRoute}
                            >
                                <DeveloperPage />
                            </CanRouteToDeveloperPage>
                        }
                    />
                    <Route
                        path={requestFeatureRoute}
                        element={<RequestFeaturePage />}
                    />
                </Route>
                <Route
                    path={`${mainRoute}`}
                    element={<Navigate to={visitStartRoute} replace />}
                />
                <Route
                    path={`${mainRoute}/*`}
                    element={<Navigate to={visitStartRoute} replace />}
                />
            </Route>
            <Route
                path={`${authCheckRoute}/*`}
                element={<Navigate to={signInRoute} replace />}
            />
        </Routes>
    )
}

const RequiresAuth = ({
    authCheckRoute,
    children,
}: {
    authCheckRoute: string
    children: JSX.Element
}) => {
    const { user } = useAuthContext().user
    const location = useLocation()

    if (!user) {
        return (
            <Navigate to={authCheckRoute} state={{ from: location }} replace />
        )
    }

    return children
}

// Check if visit can be recorded
const CanRouteToRecordVisitPage = ({
    redirectPath,
    children,
}: {
    redirectPath: string
    children: JSX.Element
}) => {
    const { id } = useParams()
    const { getVisit } = useVisitsContext().visits
    const location = useLocation()
    const visit = useMemo(() => (id ? getVisit(id) : undefined), [id, getVisit])

    const canRecord = useMemo(
        () => (visit?.state ? VisitModel.canRecord(visit.state) : false),
        [visit?.state]
    )

    // If visit is not loaded yet, show loader
    if (!visit) {
        return (
            <div className="flex items-center justify-center h-full">
                <Loader />
            </div>
        )
    }

    // If visit was already recorded, then redirect to visit page
    if (canRecord === false) {
        redirectPath = redirectPath.replace(':id', id!)
        return <Navigate to={redirectPath} state={{ from: location }} replace />
    }

    // Proceed to record page
    return children
}

// Check if routing to developer page is allowed
const CanRouteToDeveloperPage = ({
    redirectPath,
    children,
}: {
    redirectPath: string
    children: JSX.Element
}) => {
    const location = useLocation()

    const canRoute = process.env.REACT_APP_DEVELOPER_SETTINGS === 'true'

    if (!canRoute) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />
    }

    // Proceed to developer page
    return children
}
