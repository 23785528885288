import React, { createContext, useContext } from 'react'

import { useAuthContext } from '../components/Authentication/hooks/useAuthContext'
import { AppSettingsHookState, useAppSettings } from './_useAppSettings'
import { PreferencesHookState, usePreferences } from './_usePreferences'
import { TemplatesHookState, useTemplates } from './_useTemplates'

interface ContextState {
    appSettings: AppSettingsHookState
    templates: TemplatesHookState
    preferences: PreferencesHookState
}

// Create the context
const LocalStateContext = createContext<ContextState | null>(null)

// Context Provider component
export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { user } = useAuthContext().user
    const userId = user?._id
    const appSettings = useAppSettings()
    const templates = useTemplates(userId)
    const preferences = usePreferences(userId)

    return (
        <LocalStateContext.Provider
            value={{
                appSettings,
                templates,
                preferences,
            }}
        >
            {children}
        </LocalStateContext.Provider>
    )
}

// Custom hook to consume the context
export function useAppContext() {
    const context = useContext(LocalStateContext)
    if (context === null) {
        throw new Error(
            'useAppContext must be used within an AppContextProvider'
        )
    }
    return context
}
