import { AxiosResponse } from 'axios'

import { Language } from '../../constants'
import { Preferences } from '../models/Preference.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getPreference = (userId: string): Promise<Preferences> => {
    const url = `${baseUrl}/preferences?userId=${userId}`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                resolve(response.data.preferences)
            })
            .catch((error) => reject(error))
    })
}

const updatePreference = (
    id: string,
    payload: UpdatePreferencesPayload
): Promise<void> => {
    const url = `${baseUrl}/preferences?id=${id}`
    return authAxios.put(url, payload)
}

export interface UpdatePreferencesPayload {
    defaultTemplateId?: string
    defaultCustomTemplateId?: string
    defaultNoteLanguage?: Language
}

export const preferencesService = {
    getPreference,
    updatePreference,
}
