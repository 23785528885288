import classNames from 'classnames'
import { ReactNode } from 'react'

export const FieldRow = ({
    className,
    label,
    inputNode,
}: {
    className?: string
    label: string
    inputNode: ReactNode
}) => {
    return (
        <div
            className={classNames(
                className,
                'flex flex-col sm:flex-row items-start sm:items-center'
            )}
        >
            <FieldLabel className="flex-1 mb-2 sm:mb-0" label={label} />
            <FieldInput className="flex-1" inputNode={inputNode} />
        </div>
    )
}

export const FieldCol = ({
    className,
    label,
    inputNode,
}: {
    className?: string
    label: string
    inputNode: ReactNode
}) => {
    return (
        <div className={classNames(className, 'flex flex-col')}>
            <FieldLabel className="flex-1 mb-2" label={label} />
            <FieldInput className="flex-1" inputNode={inputNode} />
        </div>
    )
}

export const FieldLabel = ({
    className,
    label,
}: {
    className?: string
    label: string
}) => {
    return <div className={classNames(className, 'font-medium')}>{label}</div>
}

export const FieldInput = ({
    className,
    inputNode,
}: {
    className?: string
    inputNode: ReactNode
}) => {
    return <div className={classNames(className)}>{inputNode}</div>
}

export const DividerLine = () => {
    return <hr className="my-8" />
}
