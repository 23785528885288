import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import { customTemplatesService } from '../services/http/customTemplates.service'
import { templatesService } from '../services/http/templates.service'
import { CustomTemplate } from '../services/models/CustomTemplate.model'
import { Template, TemplateSortIndex } from '../services/models/Template.model'
import { useHttp } from './useHttp'

export interface TemplatesHookState {
    allTemplates: (Template | CustomTemplate)[]
    sortIndices: TemplateSortIndex[]
    isLoading: boolean
    hasLoaded: boolean
    getTemplate(id: string): Template | CustomTemplate | undefined
    setSortIndices(sortIndices: TemplateSortIndex[]): void
    addCustomTemplate(customTemplate: CustomTemplate): void
    updateCustomTemplate(customTemplate: Partial<CustomTemplate>): void
    deleteCustomTemplate(id: string): void
}

enum ACTION {
    SET_TEMPLATES,
    SET_SORT_INDICES,
    ADD_CUSTOM_TEMPLATE,
    UPDATE_CUSTOM_TEMPLATE,
    DELETE_CUSTOM_TEMPLATE,
}

interface Action {
    type: ACTION
    payload:
        | (Template | CustomTemplate | TemplateSortIndex)[]
        | CustomTemplate
        | Partial<CustomTemplate>
        | string
}

interface State {
    allTemplates: (Template | CustomTemplate)[]
    sortIndices: TemplateSortIndex[]
}

const initialState: State = {
    allTemplates: [],
    sortIndices: [],
}

const reducer = (state: State, { type, payload }: Action): State => {
    switch (type) {
        case ACTION.SET_TEMPLATES: {
            const templates = payload as (Template | CustomTemplate)[]
            const allTemplates = [...(state.allTemplates || []), ...templates]
            return {
                ...state,
                allTemplates,
            }
        }

        case ACTION.SET_SORT_INDICES: {
            const sortIndices = payload as TemplateSortIndex[]
            return {
                ...state,
                sortIndices,
            }
        }

        case ACTION.ADD_CUSTOM_TEMPLATE: {
            const customTemplate = payload as CustomTemplate
            const allTemplates = [...state.allTemplates, customTemplate]
            return {
                ...state,
                allTemplates,
            }
        }

        case ACTION.UPDATE_CUSTOM_TEMPLATE: {
            const customTemplateUpdates = payload as Partial<CustomTemplate>
            const allTemplates = state.allTemplates.map((template) => {
                if (template._id !== customTemplateUpdates._id) {
                    return template
                }
                return {
                    ...customTemplateUpdates,
                    ...customTemplateUpdates,
                } as CustomTemplate
            })
            return {
                ...state,
                allTemplates,
            }
        }

        case ACTION.DELETE_CUSTOM_TEMPLATE: {
            const id = payload as string
            const allTemplates = state.allTemplates.map((template) => {
                if (template._id !== id) {
                    return template
                }
                return {
                    ...template,
                    deletedAt: new Date(),
                } as CustomTemplate
            })
            return {
                ...state,
                allTemplates,
            }
        }

        default:
            return state
    }
}

export const useTemplates = (userId?: string): TemplatesHookState => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { allTemplates, sortIndices } = state

    const {
        getTemplatesReq,
        getCustomTemplatesReq,
        getTemplateSortIndicesReq,
        putTemplateSortIndicesReq,
        isLoading,
        hasLoaded,
    } = useHttpReq()
    const hasFetchedRef = useRef<boolean>(false)

    const allTemplatesSorted = useMemo(() => {
        const allTemplatesActive = [...allTemplates].filter(
            (template: Template | CustomTemplate) => {
                return (
                    !(template as CustomTemplate).isCustom ||
                    !(template as CustomTemplate).deletedAt
                )
            }
        )
        return sortTemplates(allTemplatesActive, sortIndices)
    }, [allTemplates, sortIndices])

    const getTemplates = useCallback(async () => {
        try {
            let templates = await getTemplatesReq()
            templates = templates.sort((a, b) => a.sortOrder - b.sortOrder)
            dispatch({ type: ACTION.SET_TEMPLATES, payload: templates })
        } catch (error) {
            console.error('Error fetching templates')
        }
    }, [getTemplatesReq])

    const getCustomTemplates = useCallback(
        async (_userId: string) => {
            try {
                const _customTemplates = await getCustomTemplatesReq(_userId)
                dispatch({
                    type: ACTION.SET_TEMPLATES,
                    payload: _customTemplates,
                })
            } catch (error) {
                console.error('Error fetching custom templates')
            }
        },
        [getCustomTemplatesReq]
    )

    const getTemplateSortIndices = useCallback(
        async (_userId: string) => {
            try {
                const sortIndices = await getTemplateSortIndicesReq(_userId)
                dispatch({
                    type: ACTION.SET_SORT_INDICES,
                    payload: sortIndices,
                })
            } catch (error) {
                console.error('Error fetching template sort indices')
            }
        },
        [getTemplateSortIndicesReq]
    )

    const getTemplate = useCallback(
        (id: string) => allTemplates.find((i) => i._id === id),
        [allTemplates]
    )

    const setSortIndices = useCallback(
        (_sortIndices: TemplateSortIndex[]) => {
            putTemplateSortIndicesReq(_sortIndices)
            dispatch({
                type: ACTION.SET_SORT_INDICES,
                payload: _sortIndices,
            })
        },
        [putTemplateSortIndicesReq]
    )

    const addCustomTemplate = useCallback((customTemplate: CustomTemplate) => {
        dispatch({
            type: ACTION.ADD_CUSTOM_TEMPLATE,
            payload: customTemplate,
        })
    }, [])

    const updateCustomTemplate = useCallback(
        (customTemplateUpdates: Partial<CustomTemplate>) => {
            dispatch({
                type: ACTION.UPDATE_CUSTOM_TEMPLATE,
                payload: customTemplateUpdates,
            })
        },
        []
    )

    const deleteCustomTemplate = useCallback((id: string) => {
        dispatch({
            type: ACTION.DELETE_CUSTOM_TEMPLATE,
            payload: id,
        })
    }, [])

    useEffect(() => {
        if (hasFetchedRef.current || !userId) {
            return
        }
        getTemplates()
        getCustomTemplates(userId)
        getTemplateSortIndices(userId)
        hasFetchedRef.current = true
    }, [userId, getTemplates, getCustomTemplates, getTemplateSortIndices])

    return {
        allTemplates: allTemplatesSorted,
        sortIndices,
        isLoading,
        hasLoaded,
        getTemplate,
        setSortIndices,
        addCustomTemplate,
        updateCustomTemplate,
        deleteCustomTemplate,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading, hasLoaded } = useHttp()

    const getTemplatesReq = useCallback(async (): Promise<Template[]> => {
        return sendRequest(templatesService.getTemplates.bind({}))
    }, [sendRequest])

    const getCustomTemplatesReq = useCallback(
        async (_userId: string): Promise<CustomTemplate[]> => {
            return sendRequest(
                customTemplatesService.getCustomTemplates.bind({}, _userId)
            )
        },
        [sendRequest]
    )

    const getTemplateSortIndicesReq = useCallback(
        async (_userId: string): Promise<TemplateSortIndex[]> => {
            return sendRequest(
                templatesService.getTemplateSortIndices.bind({}, _userId)
            )
        },
        [sendRequest]
    )

    const putTemplateSortIndicesReq = useCallback(
        async (templateSortIndices: TemplateSortIndex[]): Promise<void> => {
            return sendRequest(
                templatesService.putTemplateSortIndices.bind(
                    {},
                    templateSortIndices
                )
            )
        },
        [sendRequest]
    )

    return {
        getTemplatesReq,
        getCustomTemplatesReq,
        getTemplateSortIndicesReq,
        putTemplateSortIndicesReq,
        isLoading,
        hasLoaded,
    }
}

const sortTemplates = (
    templates: (Template | CustomTemplate)[],
    sortIndices: TemplateSortIndex[]
): (Template | CustomTemplate)[] => {
    const sortIndexMap: Record<string, number> = {}

    templates.forEach((template, index) => {
        const sortIndex =
            sortIndices.find((i) => i.templateId === template._id)?.sortIndex ??
            index + 1
        sortIndexMap[template._id] = sortIndex
    })

    const templatesSorted = templates.sort((a, b) => {
        return sortIndexMap[a._id] - sortIndexMap[b._id]
    })

    return templatesSorted
}
