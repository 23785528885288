import { useCallback, useMemo, useReducer } from 'react'
import { toast } from 'react-toastify'
import { Observable } from 'rxjs'

import { useAuthContext } from '../components/Authentication/hooks/useAuthContext'
import {
    VisitNoteProcessed,
    VisitNoteProcessing,
} from '../services/event-bus/events'
import { gptService } from '../services/http/gpt.service'
import { notesService } from '../services/http/notes.service'
import { User } from '../services/models/User.model'
import {
    VisitNote,
    VisitNoteGptResponses,
    VisitNoteIntent,
} from '../services/models/VisitNote.model'
import { useHttp } from './useHttp'

export interface VisitNotesHookState {
    visitNotes: VisitNote[]
    visitNotesGroupedByEventId: Map<string, VisitNote[]>
    isProcessing: boolean
    areVisitNotesLoading: boolean
    getVisitNoteById(id: string): VisitNote | undefined
    getVisitNotes(visitId: string): Promise<VisitNote[]>
    generateVisitNote(
        intent: VisitNoteIntent,
        templateId?: string,
        customTemplateId?: string,
        userCommand?: string,
        errorCallback?: (error: any) => void
    ): Promise<void>
    updateVisitNoteContent(id: string, content: string): Promise<void>
    updateVisitNotePartial(
        visitId: string,
        visitNoteId: string,
        updates: Partial<VisitNote>
    ): void
    deleteVisitNote(id: string): Promise<any>
}

enum ACTION {
    ADD_ALL,
    ADD,
    UPDATE_NOTE,
    UPDATE_NOTE_PARTIAL,
    UPDATE_NOTE_GPT_RESPONSES,
    GENERATE,
    DELETE,
}

interface Action {
    type: ACTION
    payload:
        | string
        | { visitId: string; visitNote: VisitNote }
        | { visitId: string; visitNotes: VisitNote[] }
        | { visitId: string; visitNoteId: string }
        | { id: string; content: string }
        | { visitId: string; visitNoteId: string; updates: Partial<VisitNote> }
        | {
              visitId: string
              visitNoteId: string
              key: keyof VisitNoteGptResponses
              gptResponses: any
          }
        | { id: string }
}

interface State {
    visitNotesMap: Map<string, VisitNote[]>
}

const initialState: State = {
    visitNotesMap: new Map(),
}

const reducer = (state: State, { type, payload }: Action): State => {
    switch (type) {
        case ACTION.ADD_ALL: {
            const { visitId, visitNotes } = payload as {
                visitId: string
                visitNotes: VisitNote[]
            }
            const visitNotesMap = new Map(state.visitNotesMap)
            visitNotesMap.set(visitId, visitNotes)
            return { ...state, visitNotesMap }
        }

        case ACTION.ADD: {
            const { visitId, visitNote } = payload as {
                visitId: string
                visitNote: VisitNote
            }
            const visitNotesMap = new Map(state.visitNotesMap)
            const visitNotes = visitNotesMap.get(visitId) || []
            visitNotesMap.set(visitId, [...visitNotes, visitNote])
            return { ...state, visitNotesMap }
        }

        case ACTION.GENERATE: {
            const { visitId, visitNote } = payload as {
                visitId: string
                visitNote: VisitNote
            }
            const visitNotesMap = new Map(state.visitNotesMap)
            let visitNotes = visitNotesMap.get(visitId) || []

            const hasVisitNote = visitNotes.some((i) => i._id === visitNote._id)
            if (hasVisitNote) {
                visitNotes = visitNotes.map((i) => {
                    if (i._id === visitNote._id) {
                        return {
                            ...i,
                            ...visitNote,
                        }
                    }
                    return i
                })
            } else {
                visitNotes = [...visitNotes, visitNote]
            }
            visitNotesMap.set(visitId, visitNotes)
            return { ...state, visitNotesMap }
        }

        case ACTION.UPDATE_NOTE: {
            const { id, content } = payload as { id: string; content: string }
            const visitNotesMap = new Map(state.visitNotesMap)
            let visitNotes = visitNotesMap.get(id) || []
            const visitNote = visitNotes.find((i) => i._id === id)
            if (visitNote) {
                visitNotes = visitNotes.map((i) =>
                    i._id === id ? { ...i, content } : i
                )
            }
            visitNotesMap.set(id, visitNotes)
            return { ...state, visitNotesMap }
        }

        case ACTION.UPDATE_NOTE_PARTIAL: {
            const { visitId, visitNoteId, updates } = payload as {
                visitId: string
                visitNoteId: string
                updates: Partial<VisitNote>
            }
            const visitNotesMap = new Map(state.visitNotesMap)
            let visitNotes = visitNotesMap.get(visitId) || []
            const visitNote = visitNotes.find((i) => i._id === visitNoteId)
            if (visitNote) {
                visitNotes = visitNotes.map((i) =>
                    i._id === visitNoteId ? { ...i, ...updates } : i
                )
            }

            visitNotesMap.set(visitId, visitNotes)
            return { ...state, visitNotesMap }
        }

        case ACTION.UPDATE_NOTE_GPT_RESPONSES: {
            const { visitId, visitNoteId, key, gptResponses } = payload as {
                visitId: string
                visitNoteId: string
                key: keyof VisitNoteGptResponses
                gptResponses: any
            }
            const visitNotesMap = new Map(state.visitNotesMap)
            let visitNotes = visitNotesMap.get(visitId) || []
            const visitNote = visitNotes.find((i) => i._id === visitNoteId)
            if (visitNote) {
                visitNotes = visitNotes.map((i) => {
                    if (i._id !== visitNoteId) {
                        return i
                    }
                    return {
                        ...i,
                        gptResponses: {
                            ...i.gptResponses,
                            [key]: gptResponses,
                        },
                    }
                })
            }

            visitNotesMap.set(visitId, visitNotes)
            return { ...state, visitNotesMap }
        }

        case ACTION.DELETE: {
            const { visitId, visitNoteId } = payload as {
                visitId: string
                visitNoteId: string
            }
            const visitNotesMap = new Map(state.visitNotesMap)
            let visitNotes = visitNotesMap.get(visitId) || []
            visitNotes = visitNotes.filter((i) => i._id !== visitNoteId)
            visitNotesMap.set(visitId, visitNotes)
            return { ...state, visitNotesMap }
        }

        default:
            return state
    }
}

export const useVisitNotes = (visitId?: string): VisitNotesHookState => {
    const { user } = useAuthContext().user
    const [state, dispatch] = useReducer(reducer, initialState)
    const { visitNotesMap } = state
    const {
        getVisitNotesReq,
        updateVisitNoteReq,
        deleteVisitNoteReq,
        generateVisitNoteReq,
        areVisitNotesLoading,
    } = useHttpReq()

    const isEmrUser = useMemo(
        () => (user ? User.isEmrUser(user) : false),
        [user]
    )

    const visitNotes = useMemo(
        () => visitNotesMap.get(visitId || '') || [],
        [visitId, visitNotesMap]
    )

    const visitNotesGroupedByEventId = useMemo(() => {
        return VisitNote.groupByEventId(visitNotes)
    }, [visitNotes])

    const isProcessing = useMemo(
        () => visitNotes?.some((i) => i.state === 'processing'),
        [visitNotes]
    )

    const getVisitNoteById = useCallback(
        (id: string) => visitNotes?.find((i) => i._id === id),
        [visitNotes]
    )

    const getVisitNotes = useCallback(
        async (_visitId: string) => {
            return getVisitNotesReq(_visitId)
                .then((_visitNotes) => {
                    dispatch({
                        type: ACTION.ADD_ALL,
                        payload: {
                            visitId: _visitId,
                            visitNotes: _visitNotes,
                        },
                    })
                    return _visitNotes
                })
                .catch((error) => {
                    console.error(error)
                    return []
                })
        },
        [getVisitNotesReq]
    )

    const generateVisitNote = useCallback(
        async (
            intent: VisitNoteIntent,
            templateId?: string,
            customTemplateId?: string,
            userCommand?: string,
            errorCallback?: (error: any) => void
        ): Promise<void> => {
            if (!visitId) {
                return undefined
            }
            let hasGptResponses = false

            return new Promise((resolve, reject) => {
                try {
                    generateVisitNoteReq(
                        visitId,
                        intent,
                        templateId,
                        customTemplateId,
                        userCommand
                    ).subscribe({
                        next: (data) => {
                            const { visitNote } = data
                            dispatch({
                                type: ACTION.GENERATE,
                                payload: { visitId, visitNote },
                            })
                            VisitNoteProcessed.emit(visitNote)

                            if (isEmrUser && !hasGptResponses) {
                                gptService
                                    .makeRequests(visitId, visitNote._id)
                                    .subscribe({
                                        next: ({ key, gptResponse }) => {
                                            dispatch({
                                                type: ACTION.UPDATE_NOTE_GPT_RESPONSES,
                                                payload: {
                                                    visitId: visitId,
                                                    visitNoteId: visitNote._id,
                                                    key,
                                                    gptResponses: gptResponse,
                                                },
                                            })
                                        },
                                        error: (error) => {
                                            console.error(error)
                                        },
                                    })
                                hasGptResponses = true
                            }
                        },
                        complete: () => {
                            resolve()
                        },
                        error: (error) => {
                            const visitNote = error.visitNote
                            if (visitNote) {
                                dispatch({
                                    type: ACTION.GENERATE,
                                    payload: { visitId, visitNote },
                                })
                                VisitNoteProcessed.emit(visitNote)
                            }
                            errorCallback?.(error)
                            reject(error)
                        },
                    })
                    VisitNoteProcessing.emit(visitId)
                } catch (error) {
                    toast.error('Error generating note')
                    return undefined
                }
            })
        },
        [isEmrUser, visitId, generateVisitNoteReq]
    )

    const updateVisitNoteContent = useCallback(
        async (id: string, content: string) => {
            return updateVisitNoteReq(id, content).then(() => {
                dispatch({
                    type: ACTION.UPDATE_NOTE,
                    payload: { id, content },
                })
            })
        },
        [updateVisitNoteReq]
    )

    const updateVisitNotePartial = useCallback(
        async (
            visitId: string,
            visitNoteId: string,
            updates: Partial<VisitNote>
        ) => {
            dispatch({
                type: ACTION.UPDATE_NOTE_PARTIAL,
                payload: { visitId, visitNoteId, updates },
            })
        },
        []
    )

    const deleteVisitNote = useCallback(
        async (id: string) => {
            const deletingVisitNote = getVisitNoteById(id)
            if (!deletingVisitNote) {
                return Promise.resolve()
            }

            const visitId = deletingVisitNote.visitId
            const visitNotesByEventId = visitNotes?.filter(
                (i) => i.eventId === deletingVisitNote.eventId
            )

            const promises: Promise<void>[] = []

            visitNotesByEventId.forEach((visitNote) => {
                promises.push(
                    deleteVisitNoteReq(visitNote._id).then(() => {
                        dispatch({
                            type: ACTION.DELETE,
                            payload: {
                                visitId,
                                visitNoteId: visitNote._id,
                            },
                        })
                    })
                )
            })

            return Promise.all(promises)
        },
        [visitNotes, getVisitNoteById, deleteVisitNoteReq]
    )

    return {
        visitNotes,
        visitNotesGroupedByEventId,
        isProcessing,
        areVisitNotesLoading,
        getVisitNoteById,
        getVisitNotes,
        generateVisitNote,
        updateVisitNoteContent,
        updateVisitNotePartial,
        deleteVisitNote,
    }
}

enum REQUEST {
    GET_ALL,
}

const useHttpReq = () => {
    const { sendRequest, requestId, isLoading } = useHttp()

    const getVisitNotesReq = useCallback(
        async (visitId: string): Promise<VisitNote[]> =>
            sendRequest(
                notesService.getNotes.bind({}, visitId),
                REQUEST.GET_ALL
            ),
        [sendRequest]
    )

    const updateVisitNoteReq = useCallback(
        (id: string, content: string) =>
            sendRequest(notesService.updateNote.bind({}, id, content)),
        [sendRequest]
    )

    const deleteVisitNoteReq = useCallback(
        (id: string) => sendRequest(notesService.deleteNote.bind({}, id)),
        [sendRequest]
    )

    const generateVisitNoteReq = useCallback(
        (
            visitId: string,
            intent: VisitNoteIntent,
            templateId?: string,
            customTemplateId?: string,
            userCommand?: string
        ): Observable<{
            visitNote: VisitNote
        }> =>
            notesService.generateNote(
                visitId,
                intent,
                templateId,
                customTemplateId,
                userCommand
            ),
        []
    )

    const areVisitNotesLoading = useMemo(
        () => isLoading && requestId === REQUEST.GET_ALL,
        [isLoading, requestId]
    )

    return {
        areVisitNotesLoading,
        getVisitNotesReq,
        updateVisitNoteReq,
        deleteVisitNoteReq,
        generateVisitNoteReq,
    }
}
