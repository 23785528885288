import MicrophoneStream from 'microphone-stream'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Subscription } from 'rxjs'

import { Language } from '../constants'
import { TranscribedContent } from '../services/event-bus/events'
import { authService } from '../services/http/auth.service'
import { servicesService } from '../services/http/services.service'
import { transcriptionsService } from '../services/http/transcriptions.service'
import { Transcript } from '../services/models/Visit.model'
import {
    audio,
    downloadAudioChunksAsFile,
    microphoneStreamToReadableStream,
} from './audio'
import { AwsTranscriber } from './AwsTranscriber'
import { screenLock } from './screenLock'
import { storage } from './storage'

// Check if the browser supports request streams
const isRequestStreamsSuppported = (() => {
    let duplexAccessed = false

    const hasContentType = new Request('', {
        body: new ReadableStream(),
        method: 'POST',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        get duplex() {
            duplexAccessed = true
            return 'half'
        },
    }).headers.has('Content-Type')

    return duplexAccessed && !hasContentType
})()

const DEFAULT_SAMPLE_RATE = 48000

export class Whisper {
    visitId: string | null = null
    languageSource: Language | null = null
    languageTarget: Language | null = null
    deviceId?: string
    audioSseAbortController: AbortController | null = null
    transcriptSseSubscription: Subscription | null = null
    transcriptSseEventSource: EventSource | null = null
    awsTranscriber: AwsTranscriber | null = null
    downloadAudioFile: boolean = false
    logAudioChunks: boolean = false
    audioChunks: any[] = []

    constructor(
        visitId: string,
        languageSource: Language | null,
        languageTarget: Language | null,
        deviceId?: string,
        downloadAudioFile: boolean = false,
        logAudioChunks: boolean = false
    ) {
        this.visitId = visitId
        this.languageSource = languageSource
        this.languageTarget = languageTarget
        this.deviceId = deviceId
        this.downloadAudioFile = downloadAudioFile
        this.logAudioChunks = logAudioChunks
    }

    async start() {
        let microphoneStream: MicrophoneStream | null = null
        try {
            microphoneStream = await audio.getStream(this.deviceId)
        } catch (error) {
            toast.error('Unable to detect microphone')
            return
        }

        // Lock screen to prevent sleep
        screenLock.lock()

        // Check if Whisper server is overflowed
        const isOverflowed = await isServerOverflowed()

        // If request streams is supported and not overflowed, then proceed with Whisper transcriber
        if (isRequestStreamsSuppported && !isOverflowed) {
            // Stream audio to python Whisper server
            this.audioChunks = []
            this.audioSseAbortController = await streamAudio(
                this.visitId!,
                this.languageSource,
                this.languageTarget,
                microphoneStream,
                (chunk: Int16Array) => {
                    const int16Array = new Int16Array(
                        chunk.buffer,
                        chunk.byteOffset,
                        chunk.byteLength / Int16Array.BYTES_PER_ELEMENT
                    )

                    if (this.logAudioChunks) {
                        console.log('audio chunk', int16Array)
                    }

                    this.audioChunks.push(int16Array)
                }
            )

            // Stream transcripts back from express server
            const response = streamTranscripts(this.visitId!)
            this.transcriptSseSubscription = response.subscription
            this.transcriptSseEventSource = response.eventSource
        }

        // Fall back to AWS transcriber
        else {
            this.awsTranscriber = new AwsTranscriber(this.visitId!)
            this.awsTranscriber.start()
        }
    }

    async stop() {
        this.transcriptSseSubscription?.unsubscribe()
        this.transcriptSseSubscription = null
        this.awsTranscriber?.stop()
        this.awsTranscriber = null
        screenLock.unlock()

        // Close audio stream after some milliseconds to allow last end-of-audio signal chunk to be sent
        audio.stopStream()
        setTimeout(
            () => this.audioSseAbortController?.abort('recording-stopped'),
            10
        )

        // Close transcripts event source after some seconds to allow last transcript to be received
        setTimeout(() => {
            this.transcriptSseEventSource?.close()
            this.transcriptSseEventSource = null
        }, 7000)

        if (this.logAudioChunks) {
            console.log('final audio chunks', this.audioChunks)
        }

        if (this.downloadAudioFile) {
            const time = moment().format('hh:mm:ss_a')
            const filename = `audio_${this.visitId}_${time}`
            const sampleRate = DEFAULT_SAMPLE_RATE

            handleDownloadAudioChunksAsFile(
                filename,
                this.audioChunks,
                sampleRate,
                () => {
                    toast.success('Audio file downloaded')
                }
            )
        }
    }
}

const isServerOverflowed = async () => {
    return await servicesService.isWhisperOverflowed()
}

const streamAudio = async (
    visitId: string,
    languageSource: Language | null,
    languageTarget: Language | null,
    microphoneStream: MicrophoneStream,
    callback: (chunks: Int16Array) => void
): Promise<AbortController> => {
    let baseUrl = 'https://dev-api.fluent.health'
    const isLocalhost = window.location.origin === 'http://localhost:3000'
    if (!isLocalhost) {
        baseUrl = (process.env.REACT_APP_API || '').replace('/api', '')
    }
    const url = `${baseUrl}/visits/${visitId}/audio`
    let token = storage.getEmrToken()
    if (token) {
        token = `emr.${token}`
    } else {
        token = storage.getAccessToken()
    }

    const headers: Record<string, string> = {
        Authorization: `Bearer ${token}`,
    }
    // if (languageSource && languageTarget) {
    //     headers['Content-Language'] = `${languageSource}, ${languageTarget}`
    // } else
    if (languageTarget && languageTarget !== 'en') {
        headers['Content-Language'] = languageTarget
    }

    const controller = new AbortController()
    const signal = controller.signal

    fetch(url, {
        method: 'POST',
        body: microphoneStreamToReadableStream(microphoneStream!, callback),
        headers,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        duplex: 'half',
        signal,
    }).catch((error: any) => {
        if (error !== 'recording-stopped') {
            console.error(error)
        }
    })

    return controller
}

const streamTranscripts = (
    visitId: string
): {
    eventSource: EventSource
    subscription: Subscription
} => {
    const { eventSource, observable } =
        transcriptionsService.getTranscriptions(visitId)

    const subscription = observable.subscribe((event) => {
        try {
            const transcript = event as Transcript

            // Emit transcript content for component consumption
            TranscribedContent.emit({
                visitId,
                transcript,
            })
        } catch (error) {
            console.error(error)
        }
    })

    return {
        eventSource,
        subscription,
    }
}

export const postAudioComplete = async (visitId: string) => {
    try {
        let token = storage.getAccessToken()
        if (token) {
            await authService.refresh()
        }

        let baseUrl = 'https://dev-api.fluent.health'
        const isLocalhost = window.location.origin === 'http://localhost:3000'
        if (!isLocalhost) {
            baseUrl = (process.env.REACT_APP_API || '').replace('/api', '')
        }

        const url = `${baseUrl}/visits/${visitId}/complete`
        token = storage.getEmrToken()
        if (token) {
            token = `emr.${token}`
        } else {
            token = storage.getAccessToken()
        }

        const headers: Record<string, string> = {
            Authorization: `Bearer ${token}`,
        }

        const response = await fetch(url, {
            method: 'POST',
            headers,
        })

        if (!response.ok) {
            throw new Error(
                `Failed to complete audio. Status: ${response.status}`
            )
        }

        return response
    } catch (error) {
        console.error('Error in postAudioComplete:', error)
        throw error
    }
}

let downloadTimeoutRef: any = null
const handleDownloadAudioChunksAsFile = (
    filename: string,
    audioChunks: Int16Array[],
    sampleRate: number,
    onDownloaded?: () => void
) => {
    if (downloadTimeoutRef) {
        clearTimeout(downloadTimeoutRef)
    }
    downloadTimeoutRef = setTimeout(() => {
        downloadAudioChunksAsFile(
            filename,
            audioChunks,
            sampleRate,
            onDownloaded
        )
    }, 250)
}
