import classNames from 'classnames'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Radio, RadioOption } from '../../../../UI/Radio'
import { FieldRow } from '../styles'

interface Props {
    className?: string
    value: string
    onChange(value: string): void
}

const Styles = styled.div``

export const NoteTypeField = ({ className, value, onChange }: Props) => {
    const options = useMemo((): RadioOption[] => {
        return [
            {
                value: 'visit',
                label: 'Patient Visit',
            },
            {
                value: 'patient_handout',
                label: 'Patient Handout',
            },
            {
                value: 'referral_letter',
                label: 'Referral Letter',
            },
        ]
    }, [])

    return (
        <Styles className={classNames(className)}>
            <FieldRow
                label="Note type"
                inputNode={
                    <Radio
                        options={options}
                        value={value}
                        onChange={onChange}
                    />
                }
            />
        </Styles>
    )
}
