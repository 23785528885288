import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { toast } from 'react-toastify'

import { useHttp } from '../../../hooks/useHttp'
import {
    eventBus,
    EventBusData,
} from '../../../services/event-bus/eventBus.service'
import {
    VisitFailedToFinish,
    VisitNoteProcessed,
    VisitNoteProcessing,
} from '../../../services/event-bus/events'
import { ErrorCode } from '../../../services/http/auth.axios'
import { visitsService } from '../../../services/http/visits.service'
import {
    Transcript,
    TranscriptPartial,
    Visit,
    VisitKey,
    VisitsGroup,
} from '../../../services/models/Visit.model'
import { VisitNote } from '../../../services/models/VisitNote.model'

export interface VisitsHookState {
    visits: Visit[]
    visitsGroups: VisitsGroup[]
    areVisitsLoading: boolean
    areVisitsLoaded: boolean
    isCreateVisitLoading: boolean
    isDeleteVisitLoading: boolean
    getVisit(id: string): Visit | undefined
    getVisits(): Promise<void>
    createVisit(
        templateId: string | undefined,
        customTemplateId: string | undefined
    ): Promise<Visit | null>
    deleteVisit(id: string): Promise<void>
    deleteVisits(ids: string[]): Promise<void>
    updateVisit(visitPartialUpdate: VisitPartialUpdate): void
    updateDuration(id: string, duration: number, propagate?: boolean): void
    createTranscript(
        id: string,
        transcript: Transcript | TranscriptPartial
    ): void
    searchVisits(searchTerm: string): void
}

enum ACTION {
    ADD_ALL,
    ADD,
    UPDATE,
    CREATE_TRANSCRIPT,
    UPDATE_DURATION,
    DELETE,
    DELETE_BULK,
    SEARCH,
}

interface Action {
    type: ACTION
    payload:
        | string
        | string[]
        | Visit
        | Visit[]
        | VisitPartialUpdate
        | TranscriptAddition
        | DurationUpdate
}

interface State {
    visits: Visit[]
    visitsFiltered: Visit[]
    visitsGroups: VisitsGroup[]
    paginationIndex: number
}

const initialState: State = {
    visits: [],
    visitsFiltered: [],
    visitsGroups: [],
    paginationIndex: 0,
}

type VisitPartialUpdate = {
    _id: string
    key: VisitKey
    value: any
    propagate?: boolean // Reduce re-renders by having less update propagation
}

type DurationUpdate = {
    _id: string
    duration: number
    propagate?: boolean
}

type TranscriptAddition = {
    _id: string
    transcript: Transcript | TranscriptPartial
}

const reducer = (state: State, { type, payload }: Action): State => {
    switch (type) {
        case ACTION.ADD_ALL: {
            const newVisits = payload as Visit[]
            const visits = [...state.visits, ...newVisits]
            const paginationIndex = state.paginationIndex + newVisits.length

            return {
                ...state,
                visits,
                visitsFiltered: visits,
                visitsGroups: VisitsGroup.filterByGroup(visits),
                paginationIndex,
            }
        }

        case ACTION.ADD: {
            const newVisit = payload as Visit
            const visits = [newVisit, ...state.visits]
            return {
                ...state,
                visits,
                visitsFiltered: visits,
                visitsGroups: VisitsGroup.filterByGroup(visits),
            }
        }

        case ACTION.UPDATE: {
            const visitPartialUpdate = payload as VisitPartialUpdate
            const propagate = visitPartialUpdate.propagate

            const visits = [...state.visits].map((i) => {
                if (i._id === visitPartialUpdate._id) {
                    return {
                        ...i,
                        [visitPartialUpdate.key]: visitPartialUpdate.value,
                    }
                }
                return i
            })

            let visitsGroups = state.visitsGroups
            if (propagate) {
                visitsGroups = VisitsGroup.filterByGroup(visits)
            }

            return {
                ...state,
                visits,
                visitsFiltered: visits,
                visitsGroups,
            }
        }

        case ACTION.CREATE_TRANSCRIPT: {
            const id = (payload as TranscriptAddition)._id
            const transcript = (payload as TranscriptAddition).transcript

            const visits = [...state.visits].map((i): Visit => {
                if (i._id === id) {
                    const transcripts = [...i.transcripts].filter(
                        (i) => !(i as TranscriptPartial).isPartial
                    )
                    transcripts.push(transcript)

                    return {
                        ...i,
                        transcripts,
                        state: 'transcribing',
                    }
                }
                return i
            })

            return {
                ...state,
                visits,
                visitsFiltered: visits,
            }
        }

        case ACTION.UPDATE_DURATION: {
            const id = (payload as TranscriptAddition)._id
            const duration = (payload as DurationUpdate).duration
            const propagate = (payload as DurationUpdate).propagate

            const visits = [...state.visits].map((i): Visit => {
                if (i._id === id) {
                    return {
                        ...i,
                        duration,
                    }
                }
                return i
            })

            let visitsGroups = state.visitsGroups
            if (propagate) {
                visitsGroups = VisitsGroup.filterByGroup(visits)
            }

            return {
                ...state,
                visits,
                visitsFiltered: visits,
                visitsGroups,
            }
        }

        case ACTION.DELETE: {
            const id = payload as string
            const visits = [...state.visits].filter((i) => i._id !== id)
            return {
                ...state,
                visits,
                visitsFiltered: visits,
                visitsGroups: VisitsGroup.filterByGroup(visits),
            }
        }

        case ACTION.DELETE_BULK: {
            const ids = payload as string[]
            const visits = [...state.visits].filter((i) => !ids.includes(i._id))
            return {
                ...state,
                visits,
                visitsFiltered: visits,
                visitsGroups: VisitsGroup.filterByGroup(visits),
            }
        }

        case ACTION.SEARCH: {
            const searchTerm = payload as string
            const visitsFiltered = Visit.filterByTitle(searchTerm, state.visits)
            return {
                ...state,
                visitsFiltered,
                visitsGroups: VisitsGroup.filterByGroup(visitsFiltered),
            }
        }

        default:
            return state
    }
}

export const useVisits = (userId?: string): VisitsHookState => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { visitsFiltered: visits, visitsGroups, paginationIndex } = state
    const {
        areVisitsLoading,
        areVisitsLoaded,
        isCreateVisitLoading,
        isDeleteVisitLoading,
        getVisitsReq,
        createVisitReq,
        deleteVisitReq,
        deleteVisitsReq,
    } = useHttpReq()
    const hasFetchedRef = useRef<boolean>(false)

    const getVisit = useCallback(
        (id: string) => visits.find((i) => i._id === id),
        [visits]
    )

    const getVisits = useCallback(async () => {
        if (!userId) {
            return Promise.resolve()
        }
        return getVisitsReq(userId, paginationIndex)
            .then((_visits) => {
                dispatch({
                    type: ACTION.ADD_ALL,
                    payload: _visits,
                })
            })
            .catch((error) => console.error(error))
    }, [userId, paginationIndex, getVisitsReq])

    const createVisit = useCallback(
        async (
            templateId: string | undefined,
            customTemplateId: string | undefined
        ) => {
            if (!userId) {
                return Promise.reject('Missing userId')
            }
            return createVisitReq(userId, templateId, customTemplateId)
                .then((newVisit) => {
                    dispatch({
                        type: ACTION.ADD,
                        payload: newVisit,
                    })
                    return newVisit
                })
                .catch((error) => {
                    if ((error?.errorCode as ErrorCode) === 'LIMIT_REACHED') {
                        toast.error(
                            'You have reached the maximum number of visits allowed for your current billing period.'
                        )
                    }
                    return null
                })
        },
        [userId, createVisitReq]
    )

    const updateVisit = useCallback(
        (visitPartialUpdate: VisitPartialUpdate) => {
            dispatch({
                type: ACTION.UPDATE,
                payload: visitPartialUpdate,
            })
        },
        []
    )

    const updateDuration = useCallback(
        async (id: string, duration: number, propagate?: boolean) => {
            dispatch({
                type: ACTION.UPDATE_DURATION,
                payload: {
                    _id: id,
                    duration,
                    propagate,
                },
            })
        },
        []
    )

    const createTranscript = useCallback(
        (id: string, transcript: Transcript | TranscriptPartial) => {
            dispatch({
                type: ACTION.CREATE_TRANSCRIPT,
                payload: {
                    _id: id,
                    transcript,
                },
            })
        },
        []
    )

    const deleteVisit = useCallback(
        async (id: string) => {
            return deleteVisitReq(id)
                .then(() => {
                    dispatch({
                        type: ACTION.DELETE,
                        payload: id,
                    })
                    return
                })
                .catch((error) => console.error(error))
        },
        [deleteVisitReq]
    )

    const deleteVisits = useCallback(
        async (ids: string[]) => {
            return deleteVisitsReq(ids)
                .then(() => {
                    dispatch({
                        type: ACTION.DELETE_BULK,
                        payload: ids,
                    })
                    return
                })
                .catch((error) => console.error(error))
        },
        [deleteVisitsReq]
    )

    const searchVisits = useCallback((searchTerm: string) => {
        dispatch({
            type: ACTION.SEARCH,
            payload: searchTerm,
        })
    }, [])

    useEffect(() => {
        if (hasFetchedRef.current) {
            return
        }
        getVisits()
        hasFetchedRef.current = true
    }, [getVisitsReq, getVisits])

    useEffect(() => {
        const subscription = eventBus
            .getObservable()
            .subscribe((event: EventBusData) => {
                switch (event.action) {
                    case VisitNoteProcessing.action: {
                        const visitId = event.payload as string
                        updateVisit({
                            _id: visitId,
                            key: 'state',
                            value: 'processing',
                            propagate: true,
                        })
                        break
                    }

                    case VisitNoteProcessed.action: {
                        const visitNote = event.payload as VisitNote

                        if (
                            visitNote.isSystemGenerated &&
                            visitNote.state === 'completed'
                        ) {
                            // TODO: Refactor these updates into simple dispatch event
                            updateVisit({
                                _id: visitNote.visitId,
                                key: 'state',
                                value: 'completed',
                                propagate: true,
                            })
                            updateVisit({
                                _id: visitNote.visitId,
                                key: 'title',
                                value: visitNote.name,
                                propagate: true,
                            })
                            updateVisit({
                                _id: visitNote.visitId,
                                key: 'templateId',
                                value: visitNote.templateId,
                                propagate: true,
                            })
                            updateVisit({
                                _id: visitNote.visitId,
                                key: 'customTemplateId',
                                value: visitNote.customTemplateId,
                                propagate: true,
                            })
                        }
                        break
                    }

                    // Reset visit state back to transcribing
                    case VisitFailedToFinish.action: {
                        const visitId = event.payload as string
                        updateVisit({
                            _id: visitId,
                            key: 'state',
                            value: 'transcribing',
                            propagate: true,
                        })
                        break
                    }
                }
            })

        return () => {
            subscription.unsubscribe()
        }
    }, [updateVisit])

    return {
        visits,
        visitsGroups,
        areVisitsLoading,
        areVisitsLoaded,
        isCreateVisitLoading,
        isDeleteVisitLoading,
        getVisit,
        getVisits,
        createVisit,
        deleteVisit,
        deleteVisits,
        updateVisit,
        updateDuration,
        createTranscript,
        searchVisits,
    }
}

enum REQUEST {
    GET_ALL,
    CREATE,
    DELETE,
    DELETE_BULK,
}

const useHttpReq = () => {
    const { sendRequest, requestId, isLoading, hasLoaded } = useHttp()

    const getVisitsReq = useCallback(
        async (userId: string, index: number): Promise<Visit[]> =>
            sendRequest(
                visitsService.getVisits.bind({}, userId, index),
                REQUEST.GET_ALL
            ),
        [sendRequest]
    )

    const createVisitReq = useCallback(
        async (
            userId: string,
            templateId: string | undefined,
            customTemplateId: string | undefined
        ): Promise<Visit> =>
            sendRequest(
                visitsService.createVisit.bind(
                    {},
                    userId,
                    templateId,
                    customTemplateId
                ),
                REQUEST.CREATE
            ),
        [sendRequest]
    )

    const deleteVisitReq = useCallback(
        async (id: string): Promise<Visit> =>
            sendRequest(visitsService.deleteVisit.bind({}, id), REQUEST.DELETE),
        [sendRequest]
    )

    const deleteVisitsReq = useCallback(
        async (ids: string[]): Promise<Visit> =>
            sendRequest(
                visitsService.deleteVisits.bind({}, ids),
                REQUEST.DELETE_BULK
            ),
        [sendRequest]
    )

    const areVisitsLoading = useMemo(
        () => isLoading && requestId === REQUEST.GET_ALL,
        [isLoading, requestId]
    )

    const areVisitsLoaded = useMemo(
        () => hasLoaded && requestId === REQUEST.GET_ALL,
        [hasLoaded, requestId]
    )

    const isCreateVisitLoading = useMemo(
        () => isLoading && requestId === REQUEST.CREATE,
        [isLoading, requestId]
    )

    const isDeleteVisitLoading = useMemo(
        () =>
            isLoading &&
            (requestId === REQUEST.DELETE || requestId === REQUEST.DELETE_BULK),
        [isLoading, requestId]
    )

    return {
        areVisitsLoading,
        areVisitsLoaded,
        isCreateVisitLoading,
        isDeleteVisitLoading,
        getVisitsReq,
        createVisitReq,
        deleteVisitReq,
        deleteVisitsReq,
    }
}
