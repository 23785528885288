import { createContext, useContext } from 'react'
import { useLocation } from 'react-router'

import { FormHookState, useForm } from './useForm'
import { HeadingSubFormHookState, useHeadingSubForm } from './useHeadingSubForm'

interface ContextState {
    form: FormHookState
    headingSubForm: HeadingSubFormHookState
}

const LocalStateContext = createContext<ContextState>(null!)

// Provider
export function LocalContextProvider({ children }: any) {
    const customTemplate = useLocation().state?.customTemplate
    const form = useForm(customTemplate)
    const headingSubForm = useHeadingSubForm(customTemplate)

    return (
        <LocalStateContext.Provider
            value={{
                form,
                headingSubForm,
            }}
        >
            {children}
        </LocalStateContext.Provider>
    )
}

// Consumer
export function useLocalContext() {
    return useContext(LocalStateContext)
}
