import classNames from 'classnames'
import { useMemo } from 'react'
import styled from 'styled-components'

import { Checkbox } from '../../../../UI/Checkbox'
import { FieldRow } from '../styles'

interface Props {
    className?: string
    values: string[]
    onChange(key: string, value: boolean): void
}

interface Option {
    value: string
    label: string
}

const Styles = styled.div``

export const InstructionsField = ({ className, values, onChange }: Props) => {
    const options = useMemo((): Option[] => {
        return [
            {
                value: 'abbreviations',
                label: 'Use medical abbreviations whenever possible.',
            },
            {
                value: 'shortPhrases',
                label: 'Use short phrases, not full sentences.',
            },
            {
                value: 'fullSentenses',
                label: 'Use full sentences in response.',
            },
        ]
    }, [])

    return (
        <Styles className={classNames(className)}>
            <FieldRow
                label="Instructions"
                inputNode={
                    <>
                        {options.map((option) => (
                            <Checkbox
                                key={option.value}
                                value={
                                    values.includes(option.value)
                                        ? 'true'
                                        : 'false'
                                }
                                label={option.label}
                                onChange={() =>
                                    onChange(
                                        option.value,
                                        !values.includes(option.value)
                                    )
                                }
                            />
                        ))}
                    </>
                }
            />
        </Styles>
    )
}
