/* eslint-disable indent */
import { TrashIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useCallback } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { headingTypeLabelRec } from '../../../../services/models/CustomTemplate.model'
import {
    DnDContext,
    DraggableItem,
    DroppableContext,
} from '../../../UI/DragNDrop'
import { IconButton } from '../../../UI/IconButton'
import { Input } from '../../../UI/Input'
import { HeadingDropdown } from './Fields/HeadingDropdown'
import { HeadingFormatsField } from './Fields/HeadingFormatsField'
import { FieldCol } from './styles'
import { useLocalContext } from './useLocalContext'

interface Props {
    className?: string
}

const Styles = styled.div``

export const HeadingSubForm = ({ className }: Props) => {
    const {
        forms,
        addForm,
        removeForm,
        reorderForms,
        updateField,
        validateField,
    } = useLocalContext().headingSubForm

    const canReorder = forms?.length > 1

    const onReorder = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return
            }

            const formsSorted = reorderItemsInArray(
                [...forms],
                result.source.index,
                result.destination.index
            )
            reorderForms(formsSorted)
        },
        [forms, reorderForms]
    )

    return (
        <Styles className={classNames(className)}>
            <FieldCol
                label="Headings"
                inputNode={
                    <>
                        <DnDContext onDragEnd={onReorder}>
                            <DroppableContext droppableId="headings-list">
                                <div className="bg-gray-50 rounded-lg border">
                                    {forms.map((form, index) => (
                                        <DraggableItem
                                            key={`heading_subform_${index}`}
                                            draggableId={`heading_subform_${index}`}
                                            index={index}
                                            isDragDisabled={!canReorder}
                                        >
                                            {(dragHandleNode) => (
                                                <div
                                                    className={classNames(
                                                        'bg-gray-50 p-5 flex',
                                                        {
                                                            'border-t':
                                                                index !== 0,
                                                        }
                                                    )}
                                                >
                                                    {canReorder &&
                                                        dragHandleNode}

                                                    {form.canUpdateHeader
                                                        .value === true ? (
                                                        <Input
                                                            name="headingName"
                                                            className="flex-1"
                                                            initialValue={
                                                                (form
                                                                    .headingName
                                                                    .value as string) ||
                                                                ''
                                                            }
                                                            error={
                                                                form.headingName
                                                                    .error
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                updateField(
                                                                    index,
                                                                    'headingName',
                                                                    value
                                                                )
                                                            }}
                                                            onBlur={() =>
                                                                validateField(
                                                                    index,
                                                                    'headingName'
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        <div className="flex-1 font-medium">
                                                            {
                                                                (
                                                                    headingTypeLabelRec as any
                                                                )[
                                                                    form
                                                                        .headingName
                                                                        .value as any
                                                                ]
                                                            }
                                                        </div>
                                                    )}

                                                    <div className="flex-1 ml-3">
                                                        <HeadingFormatsField
                                                            values={(() => {
                                                                const values =
                                                                    []
                                                                if (
                                                                    form.bullets
                                                                        .value
                                                                )
                                                                    values.push(
                                                                        'bullets'
                                                                    )
                                                                if (
                                                                    form
                                                                        .paragraph
                                                                        .value
                                                                )
                                                                    values.push(
                                                                        'paragraph'
                                                                    )
                                                                if (
                                                                    form
                                                                        .numbered
                                                                        .value
                                                                )
                                                                    values.push(
                                                                        'numbered'
                                                                    )
                                                                if (
                                                                    form
                                                                        .do_not_include_if_not_mentioned
                                                                        .value
                                                                )
                                                                    values.push(
                                                                        'do_not_include_if_not_mentioned'
                                                                    )
                                                                if (
                                                                    form
                                                                        .combine_sections_all_together
                                                                        .value
                                                                )
                                                                    values.push(
                                                                        'combine_sections_all_together'
                                                                    )
                                                                return values
                                                            })()}
                                                            onChange={(
                                                                key,
                                                                value
                                                            ) =>
                                                                updateField(
                                                                    index,
                                                                    key as any,
                                                                    value
                                                                )
                                                            }
                                                        />

                                                        <Input
                                                            name="additionalDetails"
                                                            className="mt-2"
                                                            placeholder="Other details..."
                                                            initialValue={
                                                                (form
                                                                    .additionalDetails
                                                                    .value as string) ||
                                                                ''
                                                            }
                                                            error={
                                                                form
                                                                    .additionalDetails
                                                                    .error
                                                            }
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                updateField(
                                                                    index,
                                                                    'additionalDetails',
                                                                    value
                                                                )
                                                            }}
                                                            onBlur={() =>
                                                                validateField(
                                                                    index,
                                                                    'additionalDetails'
                                                                )
                                                            }
                                                        />
                                                    </div>

                                                    <IconButton
                                                        iconNode={
                                                            <TrashIcon className="w-6 h-6 text-gray-400" />
                                                        }
                                                        onClick={() =>
                                                            removeForm(index)
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </DraggableItem>
                                    ))}
                                </div>
                            </DroppableContext>
                        </DnDContext>

                        <HeadingDropdown className="mt-4" onChange={addForm} />
                    </>
                }
            />
        </Styles>
    )
}

const reorderItemsInArray = (
    list: any[],
    startIndex: number,
    endIndex: number
): any[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}
