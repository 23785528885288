import { AxiosResponse } from 'axios'

import {
    CustomTemplate,
    CustomTemplateContent,
} from '../models/CustomTemplate.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getCustomTemplates = async (
    userId: string
): Promise<CustomTemplate[]> => {
    const url = `${baseUrl}/templates/custom?userId=${userId}`
    return authAxios.get(url).then((response: AxiosResponse) => {
        const customTemplates =
            response.data?.customTemplates?.map((obj: any) =>
                CustomTemplate.deserialize(obj)
            ) || []
        return customTemplates
    })
}

const createCustomTemplate = async (
    userId: string,
    name: string,
    content: CustomTemplateContent
): Promise<CustomTemplate> => {
    const payload = {
        userId,
        name,
        content,
    }
    const url = `${baseUrl}/templates/custom`
    return authAxios.post(url, payload).then((response: AxiosResponse) => {
        const customTemplate = CustomTemplate.deserialize(
            response.data.customTemplate
        )
        return customTemplate
    })
}

const updateCustomTemplate = (
    id: string,
    updates: Partial<CustomTemplate>
): Promise<void> => {
    const url = `${baseUrl}/templates/custom/${id}`
    return authAxios.put(url, updates)
}

const deleteCustomTemplate = (id: string): Promise<void> => {
    const url = `${baseUrl}/templates/custom/${id}`
    return authAxios.delete(url)
}

export const customTemplatesService = {
    getCustomTemplates,
    createCustomTemplate,
    updateCustomTemplate,
    deleteCustomTemplate,
}
