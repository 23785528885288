import { produce } from 'immer'
import { useCallback, useEffect, useReducer } from 'react'

import {
    BooleanField,
    FieldValue,
    initialBooleanField,
    initialTextField,
    TextField,
} from '../../../../lib/field'
import { CustomTemplate } from '../../../../services/models/CustomTemplate.model'

export class FormFields {
    headingName!: TextField
    bullets!: BooleanField
    paragraph!: BooleanField
    numbered!: BooleanField
    do_not_include_if_not_mentioned!: BooleanField
    combine_sections_all_together!: BooleanField
    canUpdateHeader!: BooleanField
    additionalDetails!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialHeadingSubForm: FormFields = {
    headingName: initialTextField,
    bullets: initialBooleanField,
    paragraph: initialBooleanField,
    numbered: initialBooleanField,
    do_not_include_if_not_mentioned: initialBooleanField,
    combine_sections_all_together: initialBooleanField,
    canUpdateHeader: initialBooleanField,
    additionalDetails: initialTextField,
}

enum ActionType {
    ADD = 'add',
    DELETE = 'delete',
    UPDATE = 'update',
    REORDER = 'reorder',
    SET = 'set',
}

type Action =
    | {
          type: ActionType.ADD
          payload: {
              headingName: string | undefined
              canUpdateHeader: boolean
          }
      }
    | { type: ActionType.DELETE; payload: number }
    | {
          type: ActionType.UPDATE
          payload: {
              index: number
              fieldKey: FormFieldsKeys
              fieldValue: any
              fieldError?: string
          }
      }
    | {
          type: ActionType.REORDER
          payload: FormFields[]
      }
    | {
          type: ActionType.SET
          payload: CustomTemplate
      }

const reducer = (forms: FormFields[], action: Action): FormFields[] => {
    return produce(forms, (draft) => {
        switch (action.type) {
            case ActionType.ADD: {
                const { headingName, canUpdateHeader } = action.payload

                draft.push({
                    ...initialHeadingSubForm,
                    headingName: {
                        value: headingName ?? null,
                        error: null,
                    },
                    canUpdateHeader: {
                        value: canUpdateHeader,
                        error: null,
                    },
                })
                break
            }

            case ActionType.DELETE: {
                draft.splice(action.payload, 1)
                break
            }

            case ActionType.UPDATE: {
                const { index, fieldKey, fieldValue, fieldError } =
                    action.payload
                draft[index][fieldKey].value = fieldValue
                draft[index][fieldKey].error = fieldError ?? null
                break
            }

            case ActionType.REORDER: {
                return action.payload
            }

            case ActionType.SET: {
                const { headings } = (action.payload as CustomTemplate).content

                headings?.forEach((heading) => {
                    const newForm: FormFields = {
                        headingName: {
                            value:
                                heading.customHeading ??
                                (heading.heading as string) ??
                                null,
                            error: null,
                        },
                        bullets: {
                            value: !!heading?.formats?.includes('bullets'),
                            error: null,
                        },
                        paragraph: {
                            value: !!heading?.formats?.includes('paragraph'),
                            error: null,
                        },
                        numbered: {
                            value: !!heading?.formats?.includes('numbered'),
                            error: null,
                        },
                        do_not_include_if_not_mentioned: {
                            value: !!heading?.formats?.includes(
                                'do_not_include_if_not_mentioned'
                            ),
                            error: null,
                        },
                        combine_sections_all_together: {
                            value: !!heading?.formats?.includes(
                                'combine_sections_all_together'
                            ),
                            error: null,
                        },
                        canUpdateHeader: {
                            value: !!heading.customHeading,
                            error: null,
                        },
                        additionalDetails: {
                            value: heading.customInstruction ?? null,
                            error: null,
                        },
                    }
                    draft.push(newForm)
                })

                break
            }

            default:
                break
        }
    })
}

export interface HeadingSubFormHookState {
    forms: FormFields[]
    addForm(headingName: string | undefined, canUpdateHeader: boolean): void
    removeForm(index: number): void
    reorderForms(forms: FormFields[]): void
    updateField(
        index: number,
        fieldKey: FormFieldsKeys,
        fieldValue: FieldValue
    ): void
    validateField(index: number, fieldKey: FormFieldsKeys): boolean
}

export const useHeadingSubForm = (
    customTemplate: CustomTemplate | undefined
): HeadingSubFormHookState => {
    const [forms, dispatch] = useReducer(reducer, [])

    const addForm = useCallback(
        (headingName: string | undefined, canUpdateHeader: boolean) => {
            dispatch({
                type: ActionType.ADD,
                payload: {
                    headingName,
                    canUpdateHeader,
                },
            })
        },
        []
    )

    const removeForm = useCallback((index: number) => {
        dispatch({ type: ActionType.DELETE, payload: index })
    }, [])

    const reorderForms = useCallback((_forms: FormFields[]) => {
        dispatch({ type: ActionType.REORDER, payload: _forms })
    }, [])

    const setForms = useCallback((_customTemplate: CustomTemplate) => {
        dispatch({ type: ActionType.SET, payload: _customTemplate })
    }, [])

    const updateField = useCallback(
        (
            index: number,
            fieldKey: FormFieldsKeys,
            fieldValue: FieldValue,
            fieldError?: string
        ) => {
            dispatch({
                type: ActionType.UPDATE,
                payload: { index, fieldKey, fieldValue, fieldError },
            })
        },
        []
    )

    const validateField = useCallback(
        (index: number, fieldKey: FormFieldsKeys): boolean => {
            const value = forms[index][fieldKey].value

            switch (fieldKey) {
                case 'headingName': {
                    if (!value) {
                        updateField(index, fieldKey, value, 'Required')
                        return false
                    }
                    return true
                }

                default: {
                    return false
                }
            }
        },
        [forms, updateField]
    )

    useEffect(() => {
        if (!customTemplate) return
        setForms(customTemplate)
    }, [customTemplate, setForms])

    return {
        forms,
        addForm,
        removeForm,
        reorderForms,
        updateField,
        validateField,
    }
}
