import {
    DocumentTextIcon,
    PencilIcon,
    PlusCircleIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useCallback } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import styled, { css } from 'styled-components'

import { useAppContext } from '../../../hooks/useAppContext'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useRoute } from '../../../hooks/useRoute'
import { CustomTemplate } from '../../../services/models/CustomTemplate.model'
import {
    Template,
    TemplateSortIndex,
} from '../../../services/models/Template.model'
import { useAuthContext } from '../../Authentication/hooks/useAuthContext'
import { Button } from '../../UI/Button'
import { DnDContext, DraggableItem, DroppableContext } from '../../UI/DragNDrop'
import { IconButton } from '../../UI/IconButton'

interface Props {
    className?: string
}

const Styles = styled.div<{ ismobile: string }>`
    .scroll-container {
        max-height: 500px;
        overflow-y: auto;
    }

    ${({ ismobile }) =>
        ismobile === 'true' &&
        css`
            max-height: 300px;
        `}
`

export const TemplatesList = ({ className }: Props) => {
    const { navTemplateForm } = useRoute()
    const { user } = useAuthContext().user
    const userId = user?._id
    const { allTemplates, setSortIndices } = useAppContext().templates
    const { isMobile } = useMediaQuery()

    const canReorder = allTemplates.length > 1

    const onReorder = useCallback(
        (result: DropResult) => {
            if (!userId || !result.destination) {
                return
            }

            const allTemplatesSorted = reorderItemsInArray(
                [...allTemplates],
                result.source.index,
                result.destination.index
            )

            const templatesSortIndices =
                allTemplatesSorted.map((template, index): TemplateSortIndex => {
                    return {
                        owner: userId,
                        templateId: template._id,
                        sortIndex: index + 1,
                    }
                }, []) || []

            setSortIndices(templatesSortIndices)
        },
        [userId, allTemplates, setSortIndices]
    )

    return (
        <Styles
            className={classNames(className)}
            ismobile={isMobile ? 'true' : 'false'}
        >
            <div className="pb-3 border-b font-medium">Templates</div>
            <div className="scroll-container">
                <DnDContext onDragEnd={onReorder}>
                    <DroppableContext droppableId="templates-list">
                        {allTemplates.map((template, index) => (
                            <DraggableItem
                                key={`${template._id}`}
                                draggableId={`${template._id}`}
                                index={index}
                                isDragDisabled={!canReorder}
                            >
                                {(dragHandleNode) => (
                                    <TemplateItem
                                        canReorder={canReorder}
                                        template={template}
                                        dragHandleNode={dragHandleNode}
                                        onEdit={() =>
                                            navTemplateForm(
                                                template._id,
                                                template as CustomTemplate
                                            )
                                        }
                                    />
                                )}
                            </DraggableItem>
                        ))}
                    </DroppableContext>
                </DnDContext>
            </div>

            <Button
                intent="text-secondary"
                className="mt-4"
                leftIconNode={
                    <PlusCircleIcon className="h-5 w-5 text-gray-400" />
                }
                label="Add new"
                onClick={() => navTemplateForm('new')}
            />
        </Styles>
    )
}

const TemplateItemStyles = styled.div`
    padding: 12px 16px;
`

const TemplateItem = ({
    canReorder,
    template,
    dragHandleNode,
    onEdit,
}: {
    canReorder: boolean
    template: Template | CustomTemplate
    dragHandleNode: ReactNode
    onEdit: () => void
}) => {
    return (
        <TemplateItemStyles className="flex items-center justify-between border-b">
            <div className="flex items-center">
                {canReorder && dragHandleNode}
                <DocumentTextIcon className="h-5 w-5 mr-3 text-gray-400" />
                <div>{template.name}</div>
            </div>
            {(template as CustomTemplate).isCustom && (
                <IconButton
                    iconNode={<PencilIcon className="h-5 w-5 text-gray-400" />}
                    onClick={onEdit}
                />
            )}
        </TemplateItemStyles>
    )
}

const reorderItemsInArray = (
    list: (Template | CustomTemplate)[],
    startIndex: number,
    endIndex: number
): (Template | CustomTemplate)[] => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}
