import { createContext, useContext } from 'react'
import { useParams } from 'react-router'

import {
    FillableEFormsHookState,
    useFillableEForms,
} from './_useFillableEForms'
import { useVisitNotes, VisitNotesHookState } from './_useVisitNotes'

interface ContextState {
    visitNotes: VisitNotesHookState
    fillableEForms: FillableEFormsHookState
}

const LocalStateContext = createContext<ContextState | null>(null)

// Provider
export function VisitNotesContextProvider({ children }: any) {
    const { id } = useParams()
    const visitNotesHookState = useVisitNotes(id)
    const fillableEForms = useFillableEForms()

    return (
        <LocalStateContext.Provider
            value={{ visitNotes: visitNotesHookState, fillableEForms }}
        >
            {children}
        </LocalStateContext.Provider>
    )
}

// Consumer
export function useVisitNotesContext() {
    const context = useContext(LocalStateContext)
    if (context === null) {
        throw new Error(
            'useVisitNotesContext must be used within a VisitNotesContextProvider'
        )
    }
    return context
}

export default useVisitNotesContext
