import { AxiosResponse } from 'axios'
import { Observable } from 'rxjs'

import { storage } from '../../lib/storage'
import { VisitNote, VisitNoteIntent } from '../models/VisitNote.model'
import { utilsService } from '../utils.service'
import authAxios from './auth.axios'
import { authService } from './auth.service'

const baseUrl = process.env.REACT_APP_API

const getNotes = async (visitId: string): Promise<VisitNote[]> => {
    const url = `${baseUrl}/notes?visitId=${visitId}`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                resolve(response.data.visitNotes as VisitNote[])
            })
            .catch((error) => reject(error))
    })
}

const updateNote = async (id: string, content: string): Promise<void> => {
    const url = `${baseUrl}/notes/${id}`
    const payload = {
        content,
    }
    return authAxios.put(url, payload)
}

const generateNote = (
    visitId: string,
    intent: VisitNoteIntent,
    templateId?: string,
    customTemplateId?: string,
    userCommand?: string
): Observable<{
    visitNote: VisitNote
}> => {
    return new Observable((observer) => {
        const token = storage.getAccessToken()
        const refreshToken = token ? authService.refresh() : Promise.resolve()

        refreshToken
            .then(() => {
                const emrToken = storage.getEmrToken()
                const token = storage.getAccessToken()

                let url = `${baseUrl}/notes/generate?visitId=${encodeURIComponent(
                    visitId
                )}&intent=${encodeURIComponent(intent)}`

                if (templateId)
                    url += `&templateId=${encodeURIComponent(templateId)}`
                if (customTemplateId)
                    url += `&customTemplateId=${encodeURIComponent(
                        customTemplateId
                    )}`
                if (userCommand)
                    url += `&userCommand=${encodeURIComponent(userCommand)}`
                if (emrToken) {
                    url += `&emrToken=${encodeURIComponent(emrToken)}`
                } else if (token) {
                    url += `&token=${encodeURIComponent(token)}`
                }

                const eventSource = new EventSource(url)

                eventSource.onmessage = (event) => {
                    try {
                        const decoded = utilsService.fromBinary(event.data)
                        const data = JSON.parse(decoded)
                        const visitNote = data.visitNote as VisitNote
                        const hasError = !!data.error

                        if (!hasError) {
                            observer.next({
                                visitNote,
                            })
                        } else {
                            observer.error({
                                visitNote,
                            })
                        }

                        if (data.endOfStream) {
                            observer.complete()
                            eventSource.close()
                        }
                    } catch (error) {
                        console.error('Error parsing event data:', error)
                    }
                }

                eventSource.onerror = (error) => {
                    observer.error(error)
                    eventSource.close()
                }
            })
            .catch(() => {
                window.location.href = '/auth/signin'
            })
    })
}

const downloadNote = (id: string): Promise<Blob> => {
    let timezone = 'America/Toronto'
    try {
        timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
        console.error(error)
    }
    const url = `${baseUrl}/notes/${id}/download?timezone=${timezone}`
    const token = storage.getEmrToken() || storage.getAccessToken()

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        xhr.send()
        xhr.responseType = 'arraybuffer'
        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], {
                    type: 'application/pdf',
                })
                resolve(blob)
            } else {
                reject()
            }
        }
    })
}

const deleteNote = async (id: string): Promise<void> => {
    const url = `${baseUrl}/notes/${id}`
    return authAxios.delete(url)
}

export const notesService = {
    getNotes,
    updateNote,
    generateNote,
    downloadNote,
    deleteNote,
}
