import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
    className?: string
    label?: string | ReactNode
    value: string
    onChange: (value: string) => void
}

const Styles = styled.div`
    position: relative;

    input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
    }
`

export const Checkbox = ({ className, label, value, onChange }: Props) => {
    return (
        <Styles className={classNames(className)}>
            <div className={'flex items-center'}>
                <input
                    id={`checkbox-${value}`}
                    type="checkbox"
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(event.target.value)
                    }
                />

                {value === 'true' ? <CheckIcon /> : <UncheckedIcon />}

                <label className="ml-3" htmlFor={`checkbox-${value}`}>
                    {label}
                </label>
            </div>
        </Styles>
    )
}

const CheckIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#406CCF"
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
        </svg>
    )
}

const UncheckedIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#ccc"
        >
            <path d="M0 0h24v24H0V0z" fill="white" />
            <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
        </svg>
    )
}
