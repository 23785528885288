export class CustomTemplate {
    _id!: string
    owner!: string
    name!: string
    content!: CustomTemplateContent
    isCustom!: boolean
    createdAt!: Date
    updatedAt!: Date
    deletedAt?: Date

    static deserialize(obj: any): CustomTemplate {
        const model: CustomTemplate = Object.assign({}, obj)
        model.isCustom = true
        model.createdAt = new Date(obj.createdAt)
        model.updatedAt = new Date(obj.updatedAt)
        if (obj.deletedAt) {
            model.deletedAt = new Date(obj.deletedAt)
        }
        return model
    }
}

export type CustomTemplateContent = {
    type: Type
    headings: Heading[]
    instructions?: Instruction[]
    additionalDetails?: string
}

type Type = 'visit' | 'referral_letter' | 'patient_handout'

type Heading = {
    heading?: HeadingType
    formats?: Format[]
    customHeading?: string
    customInstruction?: string
}

type HeadingType =
    | 'subjective'
    | 'objective'
    | 'assessment'
    | 'plan'
    | 'investigations'
    | 'history_of_presenting_illness'
    | 'chief_complaint'
    | 'medical_history'
    | 'social_history'
    | 'surgical_history'
    | 'family_history'
    | 'medications'
    | 'allergies'

type Format =
    | 'bullets'
    | 'paragraph'
    | 'numbered'
    | 'do_not_include_if_not_mentioned'
    | 'combine_sections_all_together'

type Instruction =
    | 'medical_abbreviations'
    | 'short_phrases'
    | 'full_sentences'
    | 'incorporate_notepad'
    | 'note_language'

export const headingTypeLabelRec: Record<HeadingType, string> = {
    subjective: 'Subjective',
    objective: 'Objective',
    assessment: 'Assessment',
    plan: 'Plan',
    investigations: 'Investigations',
    history_of_presenting_illness: 'History of Presenting Illness',
    chief_complaint: 'Chief Complaint',
    medical_history: 'Medical History',
    social_history: 'Social History',
    surgical_history: 'Surgical History',
    family_history: 'Family History',
    medications: 'Medications',
    allergies: 'Allergies',
}
