import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import useVisitNotesContext from '../../hooks/useVisitNotesContext'
import { gptService } from '../../services/http/gpt.service'
import {
    VisitNote,
    VisitNoteGptResponses,
} from '../../services/models/VisitNote.model'
import { postMessageService } from '../../services/postMessage.service'
import { Button } from './Button'

export interface AutofillEFormsModalProps {
    visitId: string
    visitNoteId: string
    fillableEForms: any[]
    gptResponses?: VisitNoteGptResponses
    isOpen?: boolean
    onClose?(): void
    onConfirm?(): void
    onCancel?(): void
}

const Styles = styled.div`
    button {
        min-height: unset;
        padding: 6px 10px;
    }
`

export const AutofillEFormsModal = ({
    visitId,
    visitNoteId,
    fillableEForms,
    gptResponses,
    isOpen,
    onClose,
}: AutofillEFormsModalProps) => {
    const eforms = useMemo(
        () => postMessageService.getPatientContext()?.eforms || [],
        []
    )

    const eformsSorted = useMemo(() => {
        return eforms?.sort((a: any, b: any) => {
            const isAFillable = fillableEForms.some(
                (i) => i.name === a.formName
            )
            const isBFillable = fillableEForms.some(
                (i) => i.name === b.formName
            )

            if (isAFillable && !isBFillable) {
                return -1
            } else if (!isAFillable && isBFillable) {
                return 1
            } else {
                return a.formName.localeCompare(b.formName)
            }
        })
    }, [fillableEForms, eforms])

    const hasEForms = fillableEForms?.length > 0

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose!}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Styles className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                <div className="w-full">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-base font-semibold leading-6 text-gray-900"
                                    >
                                        Auto-fill eForms
                                    </Dialog.Title>

                                    <Dialog.Description className="w-full mt-6">
                                        {hasEForms && (
                                            <>
                                                {eformsSorted.map(
                                                    (eform, index) => {
                                                        const canAutoFill =
                                                            fillableEForms.some(
                                                                (i) =>
                                                                    i.id ===
                                                                    eform.fid
                                                            )

                                                        const autoFilledEForm =
                                                            gptResponses?.autoFilledEForms?.find(
                                                                (i: any) =>
                                                                    i.id ===
                                                                    eform.fid
                                                            )

                                                        return (
                                                            <EFormRow
                                                                key={index}
                                                                visitId={
                                                                    visitId
                                                                }
                                                                visitNoteId={
                                                                    visitNoteId
                                                                }
                                                                eform={eform}
                                                                gptResponses={
                                                                    gptResponses
                                                                }
                                                                autoFilledEForm={
                                                                    autoFilledEForm
                                                                }
                                                                canAutoFill={
                                                                    canAutoFill
                                                                }
                                                                onClose={
                                                                    onClose
                                                                }
                                                            />
                                                        )
                                                    }
                                                )}
                                            </>
                                        )}

                                        {!hasEForms && (
                                            <div>
                                                <p className="text-gray-500">
                                                    There are no eForms
                                                    available to auto-fill for
                                                    this patient.
                                                </p>
                                            </div>
                                        )}
                                    </Dialog.Description>

                                    <div className="flex items-center justify-end mt-8">
                                        <Button
                                            intent="text"
                                            className={classNames(
                                                'text-sm ml-3'
                                            )}
                                            onClick={onClose}
                                            label="Close"
                                        />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Styles>
            </Dialog>
        </Transition.Root>
    )
}

const EFormRowStyles = styled.div`
    padding: 4px 8px;

    &:nth-of-type(odd) {
        background-color: #fafafa;
    }
`

const EFormRow = ({
    visitId,
    visitNoteId,
    eform,
    autoFilledEForm,
    gptResponses,
    canAutoFill,
    onClose,
}: {
    visitId: string
    visitNoteId: string
    eform: any
    autoFilledEForm: any | undefined
    gptResponses: VisitNoteGptResponses | undefined
    canAutoFill: boolean
    onClose?(): void
}) => {
    const { updateVisitNotePartial } = useVisitNotesContext().visitNotes
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onClick = useCallback(async () => {
        try {
            if (autoFilledEForm) {
                postMessageService.sendFilledEForm(eform, autoFilledEForm)
                window?.opener?.focus()
                onClose?.()
                return
            }

            setIsLoading(true)

            // Fill eForm
            const jsonData = {
                ...eform.jsonData,
                id: eform.fid,
                name: eform.formName,
            }
            const gptResponse = await gptService.fillEForm(
                visitId,
                visitNoteId,
                jsonData
            )

            // Update visit note with gpt response
            const partialUpdates: Partial<VisitNote> = {
                gptResponses: {
                    ...gptResponses,
                    autoFilledEForms: gptResponse?.eforms ?? [],
                },
            }
            updateVisitNotePartial(visitId, visitNoteId, partialUpdates)

            // Send filled eForm to EMR parent window
            postMessageService.sendFilledEForm(eform, gptResponse)
            window?.opener?.focus()
        } catch (error) {
            console.error('Error filling eForm:', error)
            toast.error('Failed to auto-fill eForm')
        } finally {
            setIsLoading(false)
            onClose?.()
        }
    }, [
        visitId,
        visitNoteId,
        eform,
        autoFilledEForm,
        gptResponses,
        updateVisitNotePartial,
        onClose,
    ])

    if (!canAutoFill) {
        return null
    }

    return (
        <EFormRowStyles
            className={classNames('w-full flex items-center justify-between', {
                'text-gray-400': !canAutoFill,
            })}
        >
            <div className="truncate mr-3">{eform.formName}</div>
            {canAutoFill && (
                <Button
                    intent="text-primary"
                    label="Auto-fill"
                    onClick={onClick}
                    isLoading={isLoading}
                />
            )}
        </EFormRowStyles>
    )
}
