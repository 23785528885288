import classNames from 'classnames'
import styled from 'styled-components'

import { DividerLine } from '../../UI/DividerLine'
import SelectDefaultTemplate from '../Account/SelectDefaultTemplate/SelectDefaultTemplate'
import { TemplatesList } from './TemplatesList'

interface Props {
    className?: string
}

const Styles = styled.div``

export const ManageTemplates = ({ className }: Props) => {
    return (
        <Styles className={classNames(className)}>
            <SelectDefaultTemplate />
            <DividerLine className="my-8" />
            <TemplatesList />
        </Styles>
    )
}
