import { AxiosResponse } from 'axios'

import { Template, TemplateSortIndex } from '../models/Template.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getTemplates = async (): Promise<Template[]> => {
    const url = `${baseUrl}/templates/`
    return authAxios.get(url).then((response: AxiosResponse) => {
        const templates = (response.data?.templates as Template[]) || []
        return templates
    })
}

const getTemplateSortIndices = async (
    userId: string
): Promise<TemplateSortIndex[]> => {
    const url = `${baseUrl}/templates/sort?userId=${userId}`
    return authAxios.get(url).then((response: AxiosResponse) => {
        const templateSortIndices =
            (response.data?.templateSortIndices as TemplateSortIndex[]) || []
        return templateSortIndices
    })
}

const putTemplateSortIndices = (
    templatesSortIndices: TemplateSortIndex[]
): Promise<void> => {
    const url = `${baseUrl}/templates/sort`
    const payload = { templatesSortIndices }
    return authAxios.put(url, payload)
}

export const templatesService = {
    getTemplates,
    getTemplateSortIndices,
    putTemplateSortIndices,
}
