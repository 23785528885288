import { Combobox, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import styled from 'styled-components'

import { headingTypeLabelRec } from '../../../../../services/models/CustomTemplate.model'
import { useLocalContext } from '../useLocalContext'

interface Props {
    className?: string
    onChange(headingName: string, canUpdateHeader: boolean): void
}

const Styles = styled.div`
    position: relative;

    .combobox-options-wrapper {
        width: 100%;
        max-height: 200px;
        margin-top: 48px;
        overflow-y: auto;
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

        li {
            padding: 8px 12px;

            &:hover {
                cursor: pointer;
            }
        }
    }
`

export const HeadingDropdown = ({ className, onChange }: Props) => {
    const { forms } = useLocalContext().headingSubForm

    const headings = useMemo(
        () => forms?.map((form) => form.headingName.value),
        [forms]
    )

    const [query, setQuery] = useState<string>('')

    const optionsFiltered = useMemo((): Option[] => {
        const _options = options
            .filter((option) => headings?.indexOf(option.value) === -1)
            .filter((option) => {
                return option.label.toLowerCase().includes(query.toLowerCase())
            })

        if (_options.length === 0) {
            _options.push({ value: query, label: `Add: "${query}"` })
        }
        return _options
    }, [query, headings])

    return (
        <Styles className={classNames(className)}>
            <Combobox
                onChange={(value: string) => {
                    const option = options.find(
                        (option) => option.value === value
                    )
                    const canUpdateHeader = !option
                    onChange(value as string, canUpdateHeader)
                    setQuery('')
                }}
                nullable
            >
                <Combobox.Button as="div">
                    <Combobox.Input
                        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:leading-6"
                        placeholder="Enter heading..."
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={(option: Option) => option?.label}
                    />
                </Combobox.Button>
                <Transition
                    className="combobox-options-wrapper"
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <Combobox.Options>
                        {optionsFiltered.map((option: Option) => (
                            <Combobox.Option
                                className="hover:bg-gray-100"
                                key={option.value}
                                value={option.value}
                            >
                                {option.label}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </Transition>
            </Combobox>
        </Styles>
    )
}

interface Option {
    value: string
    label: string
}

const options: Option[] = [
    {
        value: 'subjective',
        label: headingTypeLabelRec.subjective,
    },
    {
        value: 'objective',
        label: headingTypeLabelRec.objective,
    },
    {
        value: 'assessment',
        label: headingTypeLabelRec.assessment,
    },
    {
        value: 'plan',
        label: headingTypeLabelRec.plan,
    },
    {
        value: 'investigations',
        label: headingTypeLabelRec.investigations,
    },
    {
        value: 'history_of_presenting_illness',
        label: headingTypeLabelRec.history_of_presenting_illness,
    },
    {
        value: 'chief_complaint',
        label: headingTypeLabelRec.chief_complaint,
    },
    {
        value: 'medical_history',
        label: headingTypeLabelRec.medical_history,
    },
    {
        value: 'social_history',
        label: headingTypeLabelRec.social_history,
    },
    {
        value: 'surgical_history',
        label: headingTypeLabelRec.surgical_history,
    },
    {
        value: 'family_history',
        label: headingTypeLabelRec.family_history,
    },
    {
        value: 'medications',
        label: headingTypeLabelRec.medications,
    },
    {
        value: 'allergies',
        label: headingTypeLabelRec.allergies,
    },
]
