import * as Sentry from '@sentry/react'

const environment = process.env.REACT_APP_ENV || 'dev'

export const sentry = (() => {
    const init = () => {
        // Don't initialize Sentry in localhost
        if (environment === 'localhost') {
            return
        }

        Sentry.init({
            dsn: 'https://41f691c04ffe5fd9310f7108c0866520@o4506633675014144.ingest.sentry.io/4506633677045760',
            environment,
            integrations: [
                new Sentry.BrowserTracing({
                    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                    tracePropagationTargets: [
                        'localhost',
                        /^https:\/\/yourserver\.io\/api/,
                    ],
                }),
                new Sentry.Replay({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        })
    }

    return {
        init,
    }
})()
