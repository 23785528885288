import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useAppContext } from '../../../../hooks/useAppContext'
import { Loader } from '../../../UI/Loader'
import { TemplatesListBox } from '../../../UI/TemplatesListBox'

interface Props {
    className?: string
}

const Styles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 280px;

    ul {
        width: 280px;
    }
`

const SelectDefaultTemplate = ({ className }: Props) => {
    const { isLoading } = useAppContext().templates
    const { preferences, isPreferenceLoading, updatePreferences } =
        useAppContext().preferences
    const [templateId, setTemplateId] = useState<string | undefined>(undefined)
    const [customTemplateId, setCustomTemplateId] = useState<
        string | undefined
    >(undefined)

    useEffect(() => {
        if (templateId || customTemplateId) {
            return
        }
        if (preferences?.defaultTemplateId) {
            setTemplateId(preferences.defaultTemplateId)
        } else if (preferences?.defaultCustomTemplateId) {
            setCustomTemplateId(preferences.defaultCustomTemplateId)
        }
    }, [
        templateId,
        customTemplateId,
        preferences?.defaultTemplateId,
        preferences?.defaultCustomTemplateId,
    ])

    const onChange = useCallback(
        (_templateId: string, isCustomTemplate: boolean) => {
            if (!preferences?._id) {
                return
            }
            setTemplateId(!isCustomTemplate ? _templateId : undefined)
            setCustomTemplateId(isCustomTemplate ? _templateId : undefined)
            updatePreferences(preferences._id, {
                defaultTemplateId: !isCustomTemplate ? _templateId : undefined,
                defaultCustomTemplateId: isCustomTemplate
                    ? _templateId
                    : undefined,
            }).catch((error) => {
                console.error(error)
                toast.error('Unable to update preferences')
            })
        },
        [preferences?._id, updatePreferences]
    )

    if (isLoading || isPreferenceLoading) {
        return <Loader />
    }

    return (
        <Styles className={classNames(className)}>
            <div className="block text-sm font-medium mb-1 leading-6 text-gray-900">
                Default Template for new recordings
            </div>
            <TemplatesListBox
                templateId={customTemplateId ?? templateId}
                onChange={onChange}
            />
        </Styles>
    )
}

export default SelectDefaultTemplate
