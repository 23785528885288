import classNames from 'classnames'
import { ReactNode, useCallback } from 'react'
import styled from 'styled-components'

import { Language } from '../../constants'
import { useRoute } from '../../hooks/useRoute'
import { Visit } from '../../services/models/Visit.model'
import { useBillingContext } from '../Billing/hooks/useBillingContext'
import { useVisitsContext } from '../Visits/hooks/useVisitsContext'

interface Props {
    className?: string
    startRecordingOnLoad?: boolean
    templateId?: string
    customTemplateId?: string
    languageSource?: Language
    languageTarget?: Language
    onCreated?(): void
    childNode(isLoading: boolean): ReactNode
}

const Styles = styled.div`
    display: inline-block;
`

export const CreateVisit = ({
    className,
    startRecordingOnLoad,
    onCreated,
    childNode,
    templateId,
    customTemplateId,
    languageSource,
    languageTarget,
}: Props) => {
    const { navVisitRecord } = useRoute()
    const { createVisit, isCreateVisitLoading } = useVisitsContext().visits
    const { showPreview } = useVisitsContext().view
    const { billingUsages, increaseVisitsCreated, getBillingUsages } =
        useBillingContext().billingUsages

    const onClick = useCallback(
        (event: any) => {
            event.stopPropagation()

            createVisit(templateId, customTemplateId)
                .then((newVisit: Visit | null) => {
                    if (!newVisit) {
                        return
                    }

                    // Navigate to record page
                    navVisitRecord(
                        newVisit._id,
                        newVisit,
                        startRecordingOnLoad,
                        templateId,
                        customTemplateId,
                        languageSource,
                        languageTarget
                    )
                    showPreview()

                    if (billingUsages) {
                        // Update billing usages
                        increaseVisitsCreated()
                    } else {
                        getBillingUsages()
                    }

                    if (onCreated) {
                        onCreated()
                    }
                })
                .catch((error) => console.error(error))
        },
        [
            createVisit,
            billingUsages,
            startRecordingOnLoad,
            increaseVisitsCreated,
            navVisitRecord,
            showPreview,
            onCreated,
            templateId,
            customTemplateId,
            languageSource,
            languageTarget,
            getBillingUsages,
        ]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isCreateVisitLoading)}
        </Styles>
    )
}
