import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import useVisitNotesContext from '../../../../../hooks/useVisitNotesContext'
import { Button } from '../../../../UI/Button'
import { NotepadEditor } from '../../../../UI/NotepadEditor'
import { useVisitsContext } from '../../../../Visits/hooks/useVisitsContext'

const Styles = styled.div`
    textarea {
        height: 125px !important;
        padding: 0;
        box-shadow: none !important;
    }
`

export const Notepad = () => {
    const { id: visitId } = useParams()
    const { getVisit } = useVisitsContext().visits
    const { generateVisitNote, isProcessing } =
        useVisitNotesContext().visitNotes

    const visit = useMemo(
        () => (visitId ? getVisit(visitId) : undefined),
        [visitId, getVisit]
    )

    const onSubmit = useCallback(
        (event?: any) => {
            event?.stopPropagation()
            generateVisitNote(
                'regenerate-note',
                visit?.templateId,
                visit?.customTemplateId
            ).catch((error) => {
                console.error('Error generating visit note:', error)
            })
        },
        [visit?.templateId, visit?.customTemplateId, generateVisitNote]
    )

    if (!visitId) {
        return null
    }

    return (
        <Styles className="border rounded-md p-3 bg-white">
            <NotepadEditor visitId={visitId} />
            <Button
                className="mt-2"
                intent="text"
                leftIconNode={
                    <ArrowPathIcon className="h-5 w-5 text-gray-500" />
                }
                label="Regenerate"
                onClick={onSubmit}
                disabled={isProcessing}
            />
        </Styles>
    )
}
