import { enableMapSet, produce } from 'immer'
import { useCallback, useReducer } from 'react'

import { gptService } from '../services/http/gpt.service'
import { useHttp } from './useHttp'

enableMapSet()

interface State {
    eformsMap: Map<string, any[]>
}

const initialState: State = {
    eformsMap: new Map<string, any[]>(),
}

enum ActionType {
    SELECT_EFORMS = 'SELECT_EFORMS',
    FILL_EFORMS = 'FILL_EFORMS',
}

type Action = {
    type: ActionType
    payload: {
        visitId: string
        eforms: any[]
    }
}

const reducer = (state: State, action: Action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case ActionType.SELECT_EFORMS: {
                draft.eformsMap.set(
                    action.payload.visitId,
                    action.payload.eforms
                )
            }
        }
    })
}

export interface FillableEFormsHookState {
    isLoading: boolean
    getFillableEForms: (
        visitId: string,
        visitNoteId: string,
        eforms: any[]
    ) => Promise<any[]>
}

export const useFillableEForms = (): FillableEFormsHookState => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { isLoading, identifyFillableEFormsReq } = useHttpReq()

    const identifyFillableEForms = useCallback(
        async (
            visitId: string,
            visitNoteId: string,
            eforms: any[]
        ): Promise<any[]> =>
            identifyFillableEFormsReq(visitId, visitNoteId, eforms).then(
                (response) => {
                    dispatch({
                        type: ActionType.SELECT_EFORMS,
                        payload: {
                            visitId,
                            eforms,
                        },
                    })
                    return response
                }
            ),
        [identifyFillableEFormsReq]
    )

    const getFillableEForms = useCallback(
        (visitId: string, visitNoteId: string, eforms: any[]) => {
            const fillableEForm = state.eformsMap.get(visitId)
            if (fillableEForm) {
                return Promise.resolve(fillableEForm)
            }
            return identifyFillableEForms(visitId, visitNoteId, eforms)
        },
        [state.eformsMap, identifyFillableEForms]
    )

    return {
        isLoading,
        getFillableEForms,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const identifyFillableEFormsReq = useCallback(
        async (
            visitId: string,
            visitNoteId: string,
            eforms: any[]
        ): Promise<any[]> =>
            sendRequest(
                gptService.identifyFillableEForms.bind(
                    {},
                    visitId,
                    visitNoteId,
                    eforms
                )
            ),
        [sendRequest]
    )

    return {
        isLoading,
        identifyFillableEFormsReq,
    }
}
