import { ReactNode } from 'react'
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd'
import styled, { css } from 'styled-components'

import Colors from '../../colors'

interface Props {
    onDragEnd: (result: DropResult) => void
    children: ReactNode
}

export const DnDContext = ({ onDragEnd, children }: Props) => {
    return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>
}

interface DroppableContextProps {
    classes?: string
    droppableId: string
    children: ReactNode
}

const DroppableContexStyles = styled.div<{ isDraggingOver: boolean }>`
    ${(props) =>
        props.isDraggingOver &&
        css`
            background-color: ${Colors.PRIMARY}1C;
            border: 2px dashed ${Colors.PRIMARY};
            border-radius: 5px;
        `}
`

export const DroppableContext = ({
    classes,
    droppableId,
    children,
}: DroppableContextProps) => {
    return (
        <Droppable
            droppableId={droppableId}
            direction="vertical"
            isDropDisabled={false}
        >
            {(provided, snapshot) => (
                <DroppableContexStyles
                    className={classes || ''}
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                >
                    {children}
                    {provided.placeholder}
                </DroppableContexStyles>
            )}
        </Droppable>
    )
}

interface DraggableItemProps {
    classes?: string
    draggableId: string
    key: string
    index: number
    isDragDisabled?: boolean
    children(DragHandleNode?: ReactNode | null): ReactNode
}

export const DraggableItem = ({
    classes,
    draggableId,
    key,
    index,
    isDragDisabled,
    children,
}: DraggableItemProps) => {
    return (
        <Draggable
            key={key}
            draggableId={draggableId}
            index={index}
            isDragDisabled={isDragDisabled}
        >
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    className={classes || ''}
                    {...provided.draggableProps}
                >
                    {children(<DragHandle {...provided.dragHandleProps} />)}
                </div>
            )}
        </Draggable>
    )
}

export const DragHandle = (props: any) => {
    return (
        <div className="mr-6" {...props}>
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 6C13 7.10457 13.8954 8 15 8C16.1046 8 17 7.10457 17 6C17 4.89543 16.1046 4 15 4C13.8954 4 13 4.89543 13 6ZM7 6C7 7.10457 7.89543 8 9 8C10.1046 8 11 7.10457 11 6C11 4.89543 10.1046 4 9 4C7.89543 4 7 4.89543 7 6ZM15 14C13.8954 14 13 13.1046 13 12C13 10.8954 13.8954 10 15 10C16.1046 10 17 10.8954 17 12C17 13.1046 16.1046 14 15 14ZM7 12C7 13.1046 7.89543 14 9 14C10.1046 14 11 13.1046 11 12C11 10.8954 10.1046 10 9 10C7.89543 10 7 10.8954 7 12ZM15 20C13.8954 20 13 19.1046 13 18C13 16.8954 13.8954 16 15 16C16.1046 16 17 16.8954 17 18C17 19.1046 16.1046 20 15 20ZM7 18C7 19.1046 7.89543 20 9 20C10.1046 20 11 19.1046 11 18C11 16.8954 10.1046 16 9 16C7.89543 16 7 16.8954 7 18Z"
                    fill={Colors.HINT}
                />
            </svg>
        </div>
    )
}
