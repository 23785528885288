import classNames from 'classnames'
import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { useRoute } from '../../../../hooks/useRoute'
import { Form } from './Form'
import { LocalContextProvider } from './useLocalContext'

interface Props {
    className?: string
}

const Styles = styled.div`
    max-width: 800px;
    margin: 0 auto;
`

export const TemplateForm = ({ className }: Props) => {
    const { id } = useParams()
    const { navTemplates } = useRoute()
    const customTemplate = useLocation().state?.customTemplate

    const isCreating = id === 'new'

    useEffect(() => {
        if (id !== 'new' && !customTemplate) {
            navTemplates()
        }
    }, [id, customTemplate, navTemplates])

    return (
        <Styles className={classNames(className)}>
            <LocalContextProvider>
                <Form isCreating={isCreating} />
            </LocalContextProvider>
        </Styles>
    )
}
