// Moment format
export const MOMENT_DATETIME_SECONDS_FORMAT = 'MMM. D, YYYY, h:mm:ssa' // Jan. 1, 2000, 3:15:10pm
export const MOMENT_DATETIME_FORMAT = 'MMM D, YYYY, h:mma' // Jan 1, 2000, 3:15pm
export const MOMENT_DATE_FORMAT = 'MMM D, YYYY' // Jan 1, 2000
export const MOMENT_SHORTDATE_FORMAT = 'MMM D' // Jan 1
export const MOMENT_TIME_FORMAT = 'h:mma' // 3:15pm
export const MOMENT_TIMESECONDS_FORMAT = 'h:mm:ssa' // 3:15:39pm

// VISITS
export const VISITS_PAGINATION_LIMIT = 20

// Form
export const PASSWORD_MIN_LENGTH = 8 // characters

// Transcription
export const TRANSCRIPTION_MIN_LENGTH = 100 // characters

// Subscriptin
export const TRIAL_PERIOD = 14 // days

// Transcriber
export type Language = 'en' | 'es' | 'fr' | 'zh' | 'hi' | 'ta' | 'ar' | 'uk' // English, Spanish, French, Chinese, Hindi, Tamil, Arabic, Ukrainian
export const LANGUAGES: Language[] = [
    'en',
    'es',
    'fr',
    'zh',
    'hi',
    'ta',
    'ar',
    'uk',
]
export const DEFAULT_LANGUAGE = LANGUAGES[0]
export const LANGUAGES_LABEL: Record<Language, string> = {
    en: 'English',
    es: 'Spanish',
    fr: 'French',
    zh: 'Chinese',
    hi: 'Hindi',
    ta: 'Tamil',
    ar: 'Arabic',
    uk: 'Ukrainian',
}
